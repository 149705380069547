import scipAccountBalanceReport from "./scipAccountBalanceReport";
import scipDelinquencyDistrictReport from "./scipDelinquencyDistrictReport";
import scipRemainingLienReport from "./scipRemainingLienReport";
import scipYoyLevyReport from "./scipYoyLevyReport";

const scipReports = {
  scipAccountBalanceReport,
  scipDelinquencyDistrictReport,
  scipRemainingLienReport,
  scipYoyLevyReport,
};

export default scipReports;

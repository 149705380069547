import assessmentBondRatesReport from "./assessmentBondRatesReport";
import backupGeneratorReport from "./backupGeneratorReport";
import {
  bondCallSummaryReport,
  bondCallSummaryReportNew,
} from "./bondCallSummaryReport";
import contractStatusReport from "./contractStatusReport";
import dailyQuoteReport from "./dailyQuoteReport";
import dateRangeReport from "./dateRangeReport";
import debtIssueDetailReport from "./debtIssueDetailReport";
import {
  heroDelinquencyReport,
  pfDelinquencyReport,
  taxPaymentDelinquencyReport,
  newDelinquencyReport,
} from "./delinquencyReport";
import enrollmentDetailReport from "./enrollmentDetailReport";
import levyDetailsReport from "./levyDetailsReport";
import outstandingPrincipalReport from "./outstandingPrincipalReport";
import paidQuoteReport from "./paidQuoteReport";
import principalInterestReport from "./principalInterestReport";
import revisedPaymentSchedulesReport from "./revisedPaymentSchedulesReport";
import mcedDebtServiceReport from "./mcedDebtServiceReport";
import payoffQuoteCounterReport from "./payoffQuoteCounterReport";
import pfgMonthlyBalanceReport from "./pfgMonthlyBalanceReport";
import outstandingPayoffRequestsReport from "./outstandingPayoffRequestsReport";
import quoteToAssignReport from "./quoteToAssignReport";
import poInfoReport from "./poInfoReport";
import pendingPaymentReport from "./pendingPaymentReport";
import cfdDelinquencyDistrictReport from "./cfdDelinquencyDistrictReport";

const reports = {
  assessmentBondRatesReport,
  bondCallSummaryReport,
  bondCallSummaryReportNew,
  dailyQuoteReport,
  taxPaymentDelinquencyReport,
  backupGeneratorReport,
  cfdDelinquencyDistrictReport,
  contractStatusReport,
  dateRangeReport,
  debtIssueDetailReport,
  newDelinquencyReport,
  enrollmentDetailReport,
  heroDelinquencyReport,
  levyDetailsReport,
  mcedDebtServiceReport,
  outstandingPayoffRequestsReport,
  outstandingPrincipalReport,
  pfDelinquencyReport,
  paidQuoteReport,
  payoffQuoteCounterReport,
  pendingPaymentReport,
  pfgMonthlyBalanceReport,
  poInfoReport,
  principalInterestReport,
  quoteToAssignReport,
  revisedPaymentSchedulesReport,
};

export default reports;

import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCol,
  MDBIcon,
  MDBRow,
} from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../components/Layout";
import Spinner from "../../components/Spinner";
import Status from "../../utils/Status";
import { useAuth } from "../auth/auth.hooks";
import {
  statusSelector,
  userSelector,
  usersSelector,
  addUser,
} from "./users.slice";
import {
  CreateUserThunk,
  DeleteUserThunk,
  FetchUsersThunk,
  FetchUserThunk,
  UpdateUserThunk,
} from "./users.thunk";

function Users({ data, onAction }) {
  const [page, setPage] = useState(1);
  const [size] = useState(10);
  const [maxPage, setMaxPage] = useState(1);
  const [displayData, setDisplayData] = useState([]);

  const changePage = (direction = 1) => {
    return () => {
      let newPage = page + direction * 1;
      if (newPage < 1) {
        newPage = 1;
      } else if (newPage > maxPage) {
        newPage = maxPage;
      }

      setPage(newPage);
    };
  };

  useEffect(() => {
    const startItem = (page - 1) * size;
    const endItem = page * size;

    setDisplayData(
      data.slice(startItem, endItem > data.length ? data.length : endItem)
    );
  }, [data, page, size]);

  useEffect(() => {
    setMaxPage(Math.ceil(data.length / size));
  }, [data, size]);

  const triggerAction = (action, user) => {
    return () => {
      onAction(action, user);
    };
  };

  return (
    <table className="table table-striped">
      <thead>
        <tr>
          <th className="users-table-user">User</th>
          <th className="users-table-admin">Is Admin</th>
          <th className="users-table-actions">Actions</th>
        </tr>
      </thead>
      <tbody>
        {displayData.map((user) => (
          <tr key={`previous-user-${user.id}`}>
            <td className="users-table-user w-50">
              <strong>{user.username}</strong>
              <br />
              {user.name}
              <br />
              {user.email}
            </td>
            <td className="users-table-admin w-25">
              {user.admin ? "Yes" : "No"}
            </td>
            <td className="users-table-actions w-25">
              <div className="btn-group">
                <button
                  className="btn btn-white text-success"
                  onClick={triggerAction("view", user)}
                >
                  <MDBIcon icon="search" fas />
                </button>
                <button
                  className="btn btn-white text-warning"
                  onClick={triggerAction("edit", user)}
                >
                  <MDBIcon icon="edit" fas />
                </button>
                <button
                  className="btn btn-white text-danger"
                  onClick={triggerAction("delete", user)}
                >
                  <MDBIcon icon="trash" fas />
                </button>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
      <tfoot>
        <tr>
          <td>
            Page: {page} / {maxPage}
          </td>
          <td colSpan="2">
            <nav aria-label="Page navigation example">
              <ul className="pagination mb-0 justify-content-end">
                <li
                  className={`page-item ${page === 1 ? "disabled" : ""}`.trim()}
                >
                  <button
                    className="page-link"
                    onClick={changePage(-1)}
                    disabled={page === 1}
                  >
                    <span className="icon me-2">
                      <MDBIcon icon="chevron-left" fas />
                    </span>
                    <span className="text">Previous</span>
                  </button>
                </li>
                <li
                  className={`page-item ${
                    page === maxPage ? "disabled" : ""
                  }`.trim()}
                >
                  <button
                    className="page-link"
                    onClick={changePage(1)}
                    disabled={page === maxPage}
                  >
                    <span className="text">Next</span>
                    <span className="icon ms-2">
                      <MDBIcon icon="chevron-right" fas />
                    </span>
                  </button>
                </li>
              </ul>
            </nav>
          </td>
        </tr>
      </tfoot>
    </table>
  );
}

export default function UserPage({ path }) {
  const [notification, setNotification] = useState(false);
  const [mode, setMode] = useState("view");
  const [adminValue, setAdminValue] = useState(null);
  const dispatch = useDispatch();

  const { user: activeUser } = useAuth();

  const user = useSelector(userSelector);
  const users = useSelector(usersSelector);
  const status = useSelector(statusSelector);

  useEffect(() => {
    dispatch(FetchUsersThunk());
  }, [dispatch]);

  const saveData = (event) => {
    event.preventDefault();

    const formData = new FormData(event.currentTarget);
    const newUser = {
      id: user?.id || undefined,
      username: formData.get("username"),
      email: formData.get("email"),
      name: formData.get("name"),
      password: formData.get("password") || undefined,
      admin: adminValue,
    };

    if (mode === "edit") {
      dispatch(UpdateUserThunk(newUser));
    }
    if (mode === "add") {
      dispatch(CreateUserThunk(newUser));
    }

    document.getElementById("main-form").reset();

    setNotification(true);
    setMode("view");
    dispatch(FetchUsersThunk());

    setTimeout(() => {
      setNotification(false);
    }, 2000);
  };

  const onAction = async (action, user) => {
    switch (action) {
      case "add":
        setMode("add");
        dispatch(addUser());
        break;
      case "view":
      case "edit":
        setMode(action);
        dispatch(FetchUserThunk(user.id));
        break;
      case "delete":
        await dispatch(DeleteUserThunk(user.id));
        dispatch(FetchUsersThunk());
        break;
      case "cancel":
        setMode("view");
        dispatch(FetchUserThunk(user.id));
        break;
      default:
    }
  };

  useEffect(() => {
    if (user) {
      setAdminValue(user.admin);
    }
  }, [user]);

  const triggerAction = (action) => {
    return () => {
      onAction(action, user);
    };
  };

  const toggleAdminValue = () => {
    setAdminValue(!adminValue);
  };

  if (!users) {
    return <Spinner isFullScreen />;
  }

  return (
    <Layout path={path}>
      <MDBRow>
        <MDBCol size="12">
          <MDBCard style={{ width: "100%" }}>
            <MDBCardBody>
              <MDBRow>
                <MDBCol size="7">
                  <h3 className="d-flex justify-content-between mb-2">
                    <span>Users List</span>

                    <MDBBtn onClick={triggerAction("add")} color="success">
                      <MDBIcon icon="plus" fas />
                    </MDBBtn>
                  </h3>

                  {status === Status.LOADING ? (
                    <Spinner isFullContent />
                  ) : (
                    <Users data={users} onAction={onAction} />
                  )}
                </MDBCol>
                {user && (
                  <MDBCol size="5">
                    <form onSubmit={saveData} id="main-form">
                      <h3 className="d-flex justify-content-between mb-2">
                        <span>User Information</span>
                        {mode === "view" ? (
                          <div className="d-flex">
                            <MDBBtn
                              onClick={triggerAction("edit")}
                              color="warning"
                              className="me-3"
                            >
                              <MDBIcon icon="edit" fas />
                            </MDBBtn>
                            <MDBBtn
                              onClick={triggerAction("delete")}
                              color="danger"
                            >
                              <MDBIcon icon="trash" fas />
                            </MDBBtn>
                          </div>
                        ) : (
                          <div className="d-flex">
                            <MDBBtn
                              type="submit"
                              color="success"
                              className="me-3"
                            >
                              <MDBIcon icon="save" fas />
                            </MDBBtn>
                            <MDBBtn
                              onClick={triggerAction("cancel")}
                              color="danger"
                            >
                              <MDBIcon icon="times" fas />
                            </MDBBtn>
                          </div>
                        )}
                      </h3>

                      {notification && (
                        <div className="alert alert-success mt-4">
                          User modified successfully.
                        </div>
                      )}

                      <div className="form-group mb-2">
                        <label htmlFor={`user-username`} className="form-label">
                          Username
                        </label>
                        <input
                          id="user-username"
                          className="form-control"
                          type="text"
                          name="username"
                          defaultValue={user.username}
                          disabled={mode === "view"}
                        />
                      </div>
                      <div className="form-group mb-2">
                        <label htmlFor={`user-name`} className="form-label">
                          Full Name
                        </label>
                        <input
                          id="user-name"
                          className="form-control"
                          type="text"
                          name="name"
                          defaultValue={user.name}
                          disabled={mode === "view"}
                        />
                      </div>
                      <div className="form-group mb-2">
                        <label htmlFor={`user-email`} className="form-label">
                          Email
                        </label>
                        <input
                          id="user-email"
                          className="form-control"
                          type="email"
                          name="email"
                          defaultValue={user.email}
                          disabled={mode === "view"}
                        />
                      </div>
                      <div className="form-group mb-2">
                        <label htmlFor={`user-password`} className="form-label">
                          Password
                        </label>
                        <input
                          id="user-password"
                          className="form-control"
                          type="password"
                          name="password"
                          autoComplete="new-password"
                          disabled={mode === "view"}
                        />
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value={user.admin}
                          id="user-admin"
                          checked={adminValue}
                          onChange={toggleAdminValue}
                          disabled={
                            mode === "view" ||
                            (user && activeUser.id === user.id)
                          }
                        />
                        <label
                          className="form-check-label"
                          htmlFor="user-admin"
                        >
                          Admin?
                        </label>
                      </div>
                    </form>
                  </MDBCol>
                )}
              </MDBRow>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    </Layout>
  );
}

import React from "react";

export default function FileField({ value, updateValue, ...others }) {
  const onInput = (event) => {
    updateValue(event.target.files[0]);
  };
  return (
    <input type="file" className="form-control" onInput={onInput} {...others} />
  );
}

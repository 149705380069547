import React from "react";

export default function Spinner({
  isFullScreen = false,
  isFullContent = false,
}) {
  const SpinnerBase = () => (
    <div className="spinner">
      <div />
      <div />
      <div />
      <div />
    </div>
  );

  if (isFullContent) {
    return (
      <div className="spinner-content">
        <SpinnerBase />
      </div>
    );
  }

  return isFullScreen ? (
    <div className="spinner-wrapper">
      <SpinnerBase />
    </div>
  ) : (
    <SpinnerBase />
  );
}

import AxiosService from "../../utils/AxiosService";

const fetchReportsRun = (report) => {
  return AxiosService.get(`/reports/${report}`);
};

const fetchAllReports = () => {
  return AxiosService.get(`/reports/`);
};

const runReport = (report, parameters) => {
  let data = parameters;
  const options = {};
  if (Object.keys(parameters).indexOf("file") >= 0) {
    data = new FormData();
    options.headers = {
      "Content-Type": "multipart/form-data",
    };
    Object.keys(parameters).forEach((parameter) => {
      data.append(parameter, parameters[parameter]);
    });
  }
  return AxiosService.post(`/reports/${report}/run`, data, options);
};
const runReportSoftCode = (report, parameters) => {
  return AxiosService.post(`/reports/${report}/run`, JSON.parse(parameters));
};

const downloadReport = (reportId) => {
  return AxiosService.get(`/reports/${reportId}/download`, {
    responseType: "arraybuffer",
  });
};

const logReport = (reportId) => {
  return AxiosService.get(`/reports/${reportId}/log`, {
    responseType: "arraybuffer",
  });
};

const fetchData = (
  database,
  table,
  fields = "*",
  joinClaise = {},
  whereClause = {},
  sortClause = {}
) => {
  return AxiosService.post(`/data/${database}/${table}`, {
    fields: fields,
    join: joinClaise,
    where: whereClause,
    sort: sortClause,
  });
};

const ReportsService = {
  fetchAllReports,
  fetchReportsRun,
  runReport,
  runReportSoftCode,
  downloadReport,
  fetchData,
  logReport,
};

export default ReportsService;

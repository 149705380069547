import React, { useState, useEffect, useRef } from "react";

export default function SelectField({
  id,
  value,
  updateValue,
  options,
  otherParameters,
  ...others
}) {
  const [visibleOptions, setVisibleOptions] = useState([]);
  const isValueVisibleRef = useRef(true);

  useEffect(() => {
    const optionsToShow = options.filter(({ showCondition }) => !showCondition || (showCondition && showCondition(otherParameters)));
    setVisibleOptions(optionsToShow);
  }, [options, otherParameters]);

  // Clear select value if there are no visible options
  useEffect(() => {
    const isValueVisible = visibleOptions.some(({ key }) => key === value);
    if (visibleOptions.length && isValueVisible !== isValueVisibleRef.current) {
      if (!isValueVisible) updateValue(visibleOptions[0].key);
      isValueVisibleRef.current = isValueVisible;
    }
  }, [visibleOptions, value, updateValue]);

  const onChange = (event) => {
    updateValue(event.target.value);
  };

  return (
    <select
      className="form-select"
      aria-label="Selector value"
      id={id}
      onChange={onChange}
      value={value}
      {...others}
    >
      {visibleOptions.map(({ key, label }) => <option value={key} key={`option-${id}-${key}`}>{label}</option>)}
    </select>
  );
}

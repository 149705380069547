const scipRemainingLienReport = {
  label: "SCIP Remaining Lien Report",
  name: "scipRemainingLienReport",
  parameters: [
    {
      name: "program",
      label: "Program",
      type: "dbfield",
      db: "scip",
      table: "Program",
      keyField: "ProgramId",
      valueField: "Name",
      sort: {
        Name: "asc",
      },
      multiple: true,
    },
    {
      name: "asOfDate",
      label: "As Of Date",
      type: "date",
      defaultValue: null,
    },
  ],
};

export default scipRemainingLienReport;

const dateRangeReport = {
  label: "Date Range Report",
  name: "dateRangeReport",
  parameters: [
    {
      name: "db",
      label: "Database",
      type: "select",
      defaultValue: "hero",
      options: [
        { key: "hero", label: "HERO" },
        { key: "data", label: "Other" },
      ],
    },
    {
      name: "countyId",
      label: "County",
      type: "dbfield",
      table: "County",
      keyField: "CountyId",
      valueField: (row) => `${row.Name} (${row.State})`,
      sort: {
        Name: "asc",
      },
      dependsOn: "db",
    },
    {
      name: "fundNum",
      label: "Fund Number",
      type: "dbfield",
      table: "Levy",
      keyField: "CountyFundNumberId",
      valueField: (row) =>
        `${row.Name} (${row.CountyFundNumberId}) ${row.TaxYear}`,
      where: {
        CountyFundNumberId: {
          null: false,
        },
      },
      sort: {
        TaxYear: "asc",
      },
      dependsOn: "countyId",
    },
    {
      name: "startDate",
      label: "Start Date",
      type: "date",
    },
    {
      name: "endDate",
      label: "End Date",
      type: "date",
    },
    {
      name: "taxYear",
      label: "Tax Year",
      type: "year",
      defaultValue: "2021",
    },
  ],
};

export default dateRangeReport;

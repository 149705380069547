import { createSlice } from "@reduxjs/toolkit";
import Status from "../../utils/Status";
import {
  CreateUserThunk,
  DeleteUserThunk,
  FetchUsersThunk,
  FetchUserThunk,
  UpdateUserThunk,
} from "./users.thunk";

const initialState = {
  users: [],
  user: null,
  status: Status.INITIAL_LOADING,
  error: null,
};

export const usersSlice = createSlice({
  name: "Users",
  initialState,
  reducers: {
    cleanupUsers(state) {
      state.users = [];
      state.user = null;
    },
    cleanupUser(state) {
      state.user = null;
    },
    addUser(state) {
      state.user = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(FetchUsersThunk.fulfilled, (state, action) => {
        const { payload } = action;
        if (payload) {
          state.status = Status.IDLE;
          state.users = payload;
        }
      })
      .addCase(FetchUsersThunk.pending, (state) => {
        state.status = Status.LOADING;
        state.users = [];
        state.user = null;
      })
      .addCase(FetchUsersThunk.rejected, (state, { payload }) => {
        state.status = Status.FAILED;
        state.error = payload;
      });
    builder
      .addCase(FetchUserThunk.fulfilled, (state, action) => {
        const { payload } = action;
        if (payload) {
          state.status = Status.IDLE;
          state.user = payload;
        }
      })
      .addCase(FetchUserThunk.pending, (state) => {
        state.status = Status.LOADING;
        state.user = null;
      })
      .addCase(FetchUserThunk.rejected, (state, { payload }) => {
        state.status = Status.FAILED;
        state.error = payload;
      });
    builder
      .addCase(CreateUserThunk.fulfilled, (state, action) => {
        const { payload } = action;
        if (payload) {
          state.status = Status.IDLE;
          state.user = payload;
          state.users.push(payload);
        }
      })
      .addCase(CreateUserThunk.pending, (state) => {
        state.status = Status.LOADING;
        state.user = null;
      })
      .addCase(CreateUserThunk.rejected, (state, { payload }) => {
        state.status = Status.FAILED;
        state.error = payload;
      });
    builder
      .addCase(UpdateUserThunk.fulfilled, (state, action) => {
        const { payload } = action;
        if (payload) {
          state.status = Status.IDLE;
          state.user = payload;
          state.users.splice(
            state.users.findIndex((user) => user.id === payload.id),
            1,
            payload
          );
        }
      })
      .addCase(UpdateUserThunk.pending, (state) => {
        state.status = Status.LOADING;
        state.user = null;
      })
      .addCase(UpdateUserThunk.rejected, (state, { payload }) => {
        state.status = Status.FAILED;
        state.error = payload;
      });
    builder
      .addCase(DeleteUserThunk.fulfilled, (state, action) => {
        const { payload } = action;
        if (payload) {
          state.status = Status.IDLE;
          state.user = null;
        }
      })
      .addCase(DeleteUserThunk.pending, (state) => {
        state.status = Status.LOADING;
        state.user = null;
      })
      .addCase(DeleteUserThunk.rejected, (state, { payload }) => {
        state.status = Status.FAILED;
        state.error = payload;
      });
  },
});

export default usersSlice.reducer;

export const { cleanupUsers, cleanupUser, addUser } = usersSlice.actions;

// selectors
export const statusSelector = (state) => state.Users.status;
export const usersSelector = (state) => state.Users.users;
export const userSelector = (state) => state.Users.user;

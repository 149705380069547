const poInfoReport = {
    label: "PO Info Report",
    name: "poInfoReport",
    parameters: [
      {
        name: "program",
        label: "Program",
        type: "select",
        defaultValue: "CLN",
        options: [
          { key: "CLN", label: "CLN" },
          { key: "CSCDA", label: "CSCDA" },
          { key: "GW", label: "GW" },
          { key: "GWC", label: "GWC" },
          { key: "GWCEDB", label: "GWCEDB" },
          { key: "GWM", label: "GWM" },
          { key: "LA HERO", label: "LA HERO" },
          { key: "LVR", label: "LVR" },
          { key: "MCED", label: "MCED" },
          { key: "OP", label: "OP" },
          { key: "SANBAG", label: "SANBAG" },
          { key: "SH", label: "SH" },
          { key: "SMS", label: "SMS" },
          { key: "TW", label: "TW" },
          { key: "TWC", label: "TWC" },
          { key: "WAB", label: "WAB" },
          { key: "WO", label: "WO" },
          { key: "WRCOG", label: "WRCOG" },
          { key: "AN", label: "AN" },
          { key: "BV", label: "BV" },
          { key: "CCF", label: "CCF" },
          { key: "CESFL", label: "CESFL" },
          { key: "CF", label: "CF" },
          { key: "CF - LA", label: "CF - LA" },
          { key: "CFW", label: "CFW" },
          { key: "CG", label: "CG" },
          { key: "E3CA", label: "E3CA" },
          { key: "E3FL", label: "E3FL" },
          { key: "GPC", label: "GPC" },
          { key: "GSFA", label: "GSFA" },
          { key: "LEIFL", label: "LEIFL" },
          { key: "NB", label: "NB" },
          { key: "PB", label: "PB" },
          { key: "PF", label: "PF" },
          { key: "PFG FL", label: "PFG FL" },
          { key: "PFG MCED", label: "PFG MCED" },
          { key: "PFLA", label: "PFLA" },
          { key: "PFMO", label: "PFMO" },
          { key: "PFW", label: "PFW" },
          { key: "RW", label: "RW" },
          { key: "SFC", label: "SFC" },
          { key: "TN", label: "TN" },
          { key: "WA", label: "WA" },
        ],
      },
    ],
  };
  
  export default poInfoReport;
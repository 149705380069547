const assessmentBondRatesReport = {
  label: "Assessment Bond Rates Report",
  name: "assessmentBondRatesReport",
  parameters: [
    {
      name: "database",
      label: "Database",
      type: "select",
      defaultValue: "hero",
      options: [
        { key: "hero", label: "HERO" },
        { key: "data", label: "Other" },
      ],
    },
  ],
};

export default assessmentBondRatesReport;

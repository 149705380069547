import { MDBCard, MDBCardBody, MDBCol, MDBRow } from "mdb-react-ui-kit";
import React from "react";
import Layout from "../../components/Layout";

export default function DashboardPage({ path }) {
  return (
    <Layout path={path}>
      <MDBRow>
        <MDBCol size="12">
          <MDBCard style={{ width: "100%" }}>
            <MDBCardBody>
              <h3>DTA Reports Platform</h3>

              <MDBRow>
                <MDBCol>
                  <p>Hello!</p>
                  <p>This is the DTA Reports platform!</p>
                </MDBCol>
              </MDBRow>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    </Layout>
  );
}

import { createAsyncThunk } from "@reduxjs/toolkit";
import AxiosErrorHandling from "../../utils/AxiosErrorHandling";
import HTTPStatus from "../../utils/HTTPStatus";
import ReportsService from "./reports.services";

export const FetchDataThunk = createAsyncThunk(
  "data/all",
  async (
    { database, table, fields = "*", joinClause = {}, whereClause = {}, sortClause = {} },
    thunkAPI
  ) => {
    try {
      const response = await ReportsService.fetchData(
        database,
        table,
        fields,
        joinClause,
        whereClause,
        sortClause
      );
      if (response.status < HTTPStatus.BAD_REQUEST) {
        return response.data;
      }

      return thunkAPI.rejectWithValue(AxiosErrorHandling(response));
    } catch (e) {
      return thunkAPI.rejectWithValue(AxiosErrorHandling(e));
    }
  }
);

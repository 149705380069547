const paidQuoteReport = {
  label: "Paid Quote Report",
  name: "paidQuoteReport",
  parameters: [
    {
      name: "agency",
      label: "Agency",
      type: "select",
      defaultValue: "ALL",
      options: [
        { key: "ALL", label: "ALL" },
        { key: "AN", label: "AN" },
        { key: "BV", label: "BV" },
        { key: "CCF", label: "CCF" },
        { key: "CESFL", label: "CESFL" },
        { key: "CF", label: "CF" },
        { key: "CF - LA", label: "CF - LA" },
        { key: "CFW", label: "CFW" },
        { key: "CG", label: "CG" },
        { key: "E3CA", label: "E3CA" },
        { key: "E3FL", label: "E3FL" },
        { key: "GPC", label: "GPC" },
        { key: "GSFA", label: "GSFA" },
        { key: "LEIFL", label: "LEIFL" },
        { key: "NB", label: "NB" },
        { key: "PB", label: "PB" },
        { key: "PF", label: "PF" },
        { key: "PFG FL", label: "PFG FL" },
        { key: "PFG MCED", label: "PFG MCED" },
        { key: "PFLA", label: "PFLA" },
        { key: "PFMO", label: "PFMO" },
        { key: "PFW", label: "PFW" },
        { key: "RW", label: "RW" },
        { key: "SFC", label: "SFC" },
        { key: "TN", label: "TN" },
        { key: "WA", label: "WA" },
      ],
    },
  ],
};

export default paidQuoteReport;

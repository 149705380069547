import laHeroApportionmentReport from "./laHeroApportionmentReport";
import solanoApportionmentReport from "./solanoApportionmentReport";
import trusteeApportionmentReport from "./trusteeApportionmentReport";

const apportionments = {
  solanoApportionmentReport,
  laHeroApportionmentReport,
  trusteeApportionmentReport,
};

export default apportionments;

import { format, parseISO } from "date-fns";
import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCol,
  MDBDropdown,
  MDBDropdownItem,
  MDBDropdownLink,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBIcon,
  MDBRow,
} from "mdb-react-ui-kit";
import React, { useEffect, useState } from "react";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../components/Layout";
import Spinner from "../../components/Spinner";
import Status from "../../utils/Status";
import ReportsService from "./reports.services";
import { reportsSelector, statusSelector } from "./reports.slice";
import { FetchReportsThunk } from "./reports.thunk";
import ReportStatus from "./ReportStatus";

function ReportStatusIcon({ status }) {
  switch (status) {
    case ReportStatus.NEW:
      return <i className="fas fa-plus text-green" />;
    case ReportStatus.INWORK:
      return <i className="fas fa-cog fa-spin text-warning" />;
    case ReportStatus.COMPLETED:
      return <i className="fas fa-check text-success" />;
    case ReportStatus.FAILED:
      return <i className="fas fa-exclamation-triangle text-danger" />;
    default:
      return <i className="fas fa-question-circle text-gray" />;
  }
}

function Reports({ data }) {
  const [page, setPage] = useState(1);
  const [size] = useState(15);
  const [maxPage, setMaxPage] = useState(1);
  const [displayData, setDisplayData] = useState([]);

  const downloadElement = (response, report, isLog = false) => {
    const blob = new Blob([response.data]);

    const blobURL =
      window.URL && window.URL.createObjectURL
        ? window.URL.createObjectURL(blob)
        : window.webkitURL.createObjectURL(blob);
    const tempLink = document.createElement("a");
    tempLink.style.display = "none";
    tempLink.href = blobURL;
    tempLink.setAttribute(
      "download",
      isLog ? `${report.name}.log` : report.name
    );

    if (typeof tempLink.download === "undefined") {
      tempLink.setAttribute("target", "_blank");
    }

    document.body.appendChild(tempLink);
    tempLink.click();
    setTimeout(function () {
      document.body.removeChild(tempLink);
      window.URL.revokeObjectURL(blobURL);
    }, 200);
  };

  const downloadReport = (report) => {
    return async () => {
      const response = await ReportsService.downloadReport(report.id);
      downloadElement(response, report);
    };
  };

  const downloadReportLog = (report) => {
    return async () => {
      const response = await ReportsService.logReport(report.id);
      downloadElement(response, report, true);
    };
  };

  const changePage = (direction = 1) => {
    return () => {
      let newPage = page + direction * 1;
      if (newPage < 1) {
        newPage = 1;
      } else if (newPage > maxPage) {
        newPage = maxPage;
      }

      setPage(newPage);
    };
  };

  useEffect(() => {
    const startItem = (page - 1) * size;
    const endItem = page * size;

    setDisplayData(
      data.slice(startItem, endItem > data.length ? data.length : endItem)
    );
  }, [data, page, size]);

  useEffect(() => {
    setMaxPage(Math.ceil(data.length / size));
  }, [data, size]);

  return (
    <table className="reports-table table table-striped">
      <thead>
        <tr>
          <th className="reports-table-run-date">Report Info</th>
          <th className="reports-table-run-date text-center">Report</th>
          <th className="reports-table-status text-center">Status</th>
          <th className="reports-table-user">Requested By</th>
          <th className="reports-table-file text-center">Action</th>
        </tr>
      </thead>
      <tbody>
        {displayData.map((report) => (
          <tr key={`previous-report-${report.id}`}>
            <td className="reports-table-run-date">
              <strong>Report Id:</strong> {report.id}
              <br />
              <strong>Report Name:</strong>{" "}
              {report.status === ReportStatus.COMPLETED ? report.name : "-"}
              <br />
              <strong>Date:</strong>{" "}
              {format(parseISO(report.date), "MM/dd/yyyy hh:mm a")}
              <br />
              <strong>Status:</strong> {report.status}
            </td>
            <td className="reports-table-status text-center">{report.task}</td>
            <td className="reports-table-status text-center">
              <ReportStatusIcon status={report.status} />
            </td>
            <td className="reports-table-user">{report.requester.name}</td>
            <td className="reports-table-file text-center">
              {report.status === ReportStatus.COMPLETED ? (
                <MDBDropdown className="mx-auto">
                  <MDBDropdownToggle size="sm">Download</MDBDropdownToggle>
                  <MDBDropdownMenu>
                    <MDBDropdownItem>
                      <MDBDropdownLink
                        href="#"
                        onClick={downloadReport(report)}
                      >
                        Download {report.name}
                      </MDBDropdownLink>
                    </MDBDropdownItem>
                    <MDBDropdownItem>
                      <MDBDropdownLink
                        href="#"
                        onClick={downloadReportLog(report)}
                      >
                        Download Log
                      </MDBDropdownLink>
                    </MDBDropdownItem>
                  </MDBDropdownMenu>
                </MDBDropdown>
              ) : report.status === ReportStatus.FAILED ? (
                report.failReason || "Check app logs"
              ) : (
                "-"
              )}
            </td>
          </tr>
        ))}
      </tbody>
      <tfoot>
        <tr>
          <td colSpan="2">
            Page: {page} / {maxPage}
          </td>
          <td colSpan="3">
            <nav aria-label="Page navigation example">
              <ul className="pagination mb-0 justify-content-end">
                <li
                  className={`page-item ${page === 1 ? "disabled" : ""}`.trim()}
                >
                  <button
                    className="page-link"
                    onClick={changePage(-1)}
                    disabled={page === 1}
                  >
                    <span className="icon me-2">
                      <MDBIcon icon="chevron-left" fas />
                    </span>
                    <span className="text">Previous</span>
                  </button>
                </li>
                <li
                  className={`page-item ${
                    page === maxPage ? "disabled" : ""
                  }`.trim()}
                >
                  <button
                    className="page-link"
                    onClick={changePage(1)}
                    disabled={page === maxPage}
                  >
                    <span className="text">Next</span>
                    <span className="icon ms-2">
                      <MDBIcon icon="chevron-right" fas />
                    </span>
                  </button>
                </li>
              </ul>
            </nav>
          </td>
        </tr>
      </tfoot>
    </table>
  );
}

export default function ReportsList({ path }) {
  const dispatch = useDispatch();

  const ranReports = useSelector(reportsSelector);
  const status = useSelector(statusSelector);

  const runRefresh = useCallback(() => {
    dispatch(FetchReportsThunk());
  }, [dispatch]);

  useEffect(() => {
    runRefresh();
  }, [runRefresh, dispatch]);

  if (!ranReports) {
    return <Spinner isFullScreen />;
  }

  return (
    <Layout path={path}>
      <MDBRow>
        <MDBCol size="12">
          <MDBCard style={{ width: "100%" }}>
            <MDBCardBody>
              <h3 className="d-flex justify-content-between mb-2">
                <span>Reports List</span>

                <MDBBtn onClick={runRefresh}>
                  <span className="me-2">
                    <MDBIcon icon="sync" fas />
                  </span>
                  <span>Refresh</span>
                </MDBBtn>
              </h3>

              {status === Status.LOADING ? (
                <Spinner isFullContent />
              ) : (
                <Reports data={ranReports} />
              )}
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    </Layout>
  );
}

const pendingPaymentReport = {
    label: "Pending Payment Report",
    name: "pendingPaymentReport",
    parameters: [
      {
        name: "date",
        label: "Registered Date",
        type: "date",
      },
      {
        name: "db",
        label: "Database",
        type: "select",
        defaultValue: "hero",
        options: [
          { key: "hero", label: "HERO" },
          { key: "data", label: "Other" },
        ],
      },
    ],
  };
  
  export default pendingPaymentReport;
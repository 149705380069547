const laHeroApportionmentReport = {
  label: "LA Hero Apportionment",
  name: "laHeroApportionmentReport",
  parameters: [
    {
      name: "db",
      label: "Database",
      type: "select",
      defaultValue: "hero",
      options: [
        { key: "hero", label: "HERO" },
        { key: "data", label: "Other" },
      ],
    },
    {
      name: "fundNum",
      label: "Fund Name",
      type: "dbfield",
      defaultValue: "",
      table: "Levy",
      keyField: "CountyFundNumberId",
      valueField: (row) =>
        `${row.Name} (${row.CountyFundNumberId}) ${row.TaxYear}`,
      fields: ["Name", "CountyFundNumberId", "TaxYear"],
      where: {
        CountyFundNumberId: {
          null: false,
        },
      },
      sort: {
        TaxYear: "asc",
      },
      dependsOn: "db",
    },
    {
      name: "taxYear",
      label: "Tax year",
      type: "decimal",
    },
    {
      name: "countyFee",
      label: "Fee that county is deducting",
      type: "decimal",
    },
    {
      name: "countyDataAmount",
      label: "County Data Amount",
      type: "decimal",
    },
    {
      name: "apportionmentPercent",
      label: "Apportionment Percent (%)",
      type: "integer",
    },
    {
      name: "file",
      label: "Excel file upload",
      type: "file",
      accept:
        ".xls,.xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel",
    },
  ],
};

export default laHeroApportionmentReport;

import { configureStore } from "@reduxjs/toolkit";
import authSliceReducer from "./pages/auth/auth.slice";
import reportsSliceReducer from "./pages/reports/reports.slice";
import usersSliceReducer from "./pages/admin/users.slice";
import dataSliceReducer from "./pages/reports/data.slice";

const store = configureStore({
  reducer: {
    Auth: authSliceReducer,
    Reports: reportsSliceReducer,
    Users: usersSliceReducer,
    Data: dataSliceReducer,
  },
});

export default store;

export default function AxiosErrorHandling(exception) {
  if (typeof exception !== "object" || !(exception instanceof Error)) {
    return "Error not valid";
  }

  let response;
  if ("response" in exception) {
    response = exception.response;
  } else if ("data" in exception) {
    response = exception;
  }

  return response
    ? (response.data && response.data.message) ||
        response.statusText ||
        exception.message
    : exception.message;
}

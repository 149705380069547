import { useDispatch, useSelector } from "react-redux";
import {
  isAuthenticatedSelector,
  logoutUser,
  userSelector,
} from "./auth.slice";

export const useAuth = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const user = useSelector(userSelector);

  return {
    user: user,
    isAuthenticated,
    logout(event) {
      event.preventDefault();
      dispatch(logoutUser());
    },
    check() {
      return !isAuthenticated;
    },
    isAdmin() {
      return isAuthenticated && user.admin;
    },
  };
};

const Status = {
  IDLE: "idle",
  LOADING: "loading",
  INITIAL_LOADING: "initial_loading",
  SUCCEEDED: "succeeded",
  FAILED: "failed",
};

Object.seal(Status);
export default Status;

export const bondCallSummaryReport = {
  label: "Bond Call Summary Report",
  name: "bondCallSummaryReport",
  parameters: [
    {
      name: "bond_call",
      label: "Bond Call Date",
      type: "date",
    },
    {
      name: "agency",
      label: "Agency",
      type: "select",
      defaultValue: "SANBAG",
      options: [
        { key: "SANBAG", label: "SANBAG" },
        { key: "LA HERO", label: "LA HERO" },
        { key: "WRCOG", label: "WRCOG" },
        { key: "MCED", label: "MCED" },
        { key: "CSCDA", label: "CSCDA" },
        { key: "AN", label: "AN" },
        { key: "CF", label: "CF" },
        { key: "CF - LA", label: "CF - LA" },
        { key: "CFW", label: "CFW" },
        { key: "PF", label: "PF" },
        { key: "PFW", label: "PFW" },
        { key: "PFLA", label: "PFLA" },
        { key: "PFG FL", label: "PFG FL" },
        { key: "PFMO", label: "PFMO" },
        { key: "E3CA", label: "E3CA" },
        { key: "E3FL", label: "FF" },
        { key: "CESFL", label: "CESFL" },
        { key: "LEIFL", label: "Leidos" },
      ],
    },
  ],
};

export const bondCallSummaryReportNew = {
  label: "Bond Call Summary Report - NEW",
  name: "bondCallSummaryReportNew",
  parameters: [
    {
      name: "bond_call",
      label: "Bond Call Date",
      type: "date",
    },
    {
      name: "program",
      label: "Program",
      type: "select",
      defaultValue: "CLN",
      options: [
        { key: "CLN", label: "CLN" },
        { key: "CSCDA", label: "CSCDA" },
        { key: "GW", label: "GW" },
        { key: "GWC", label: "GWC" },
        { key: "GWCEDB", label: "GWCEDB" },
        { key: "GWM", label: "GWM" },
        { key: "LA HERO", label: "LA HERO" },
        { key: "LVR", label: "LVR" },
        { key: "MCED", label: "MCED" },
        { key: "OP", label: "OP" },
        { key: "SANBAG", label: "SANBAG" },
        { key: "SH", label: "SH" },
        { key: "SMS", label: "SMS" },
        { key: "TW", label: "TW" },
        { key: "TWC", label: "TWC" },
        { key: "WAB", label: "WAB" },
        { key: "WO", label: "WO" },
        { key: "WRCOG", label: "WRCOG" },
        { key: "AN", label: "AN" },
        { key: "BV", label: "BV" },
        { key: "CCF", label: "CCF" },
        { key: "CESFL", label: "CESFL" },
        { key: "CF", label: "CF" },
        { key: "CF - LA", label: "CF - LA" },
        { key: "CFW", label: "CFW" },
        { key: "CG", label: "CG" },
        { key: "E3CA", label: "E3CA" },
        { key: "E3FL", label: "E3FL" },
        { key: "GPC", label: "GPC" },
        { key: "GSFA", label: "GSFA" },
        { key: "LEIFL", label: "LEIFL" },
        { key: "NB", label: "NB" },
        { key: "PB", label: "PB" },
        { key: "PF", label: "PF" },
        { key: "PFG FL", label: "PFG FL" },
        { key: "PFMO", label: "PFG MCED" },
        { key: "PFLA", label: "PFLA" },
        { key: "PFW", label: "PFW" },
        { key: "RW", label: "RW" },
        { key: "SFC", label: "SFC" },
        { key: "TN", label: "TN" },
        { key: "WA", label: "WA" },
      ],
    },
    {
      name: "separated_by_tranche",
      label: "Separated by Tranche/Indenture",
      type: "select",
      defaultValue: "no",
      options: [
        { key: "no", label: "No" },
        { key: "yes", label: "Yes" },
      ],
    },
    {
      name: "trustee",
      label: "Trustee (HERO Programs)",
      type: "select",
      defaultValue: "all",
      options: [
        { key: "all", label: "All" },
        { key: "Bank of New York Mellon", label: "Bank of New York Mellon" },
        { key: "Deutsche Bank", label: "Deutsche Bank" },
        { key: "Wilmington Trust", label: "Wilmington Trust" },
      ],
    },
  ],
};

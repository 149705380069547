import { createAsyncThunk } from "@reduxjs/toolkit";
import AxiosErrorHandling from "../../utils/AxiosErrorHandling";
import HTTPStatus from "../../utils/HTTPStatus";
import ReportsService from "./reports.services";

export const FetchReportsThunk = createAsyncThunk(
  "reports/all",
  async (_, thunkAPI) => {
    try {
      const response = await ReportsService.fetchAllReports();
      if (response.status < HTTPStatus.BAD_REQUEST) {
        return response.data;
      }

      return thunkAPI.rejectWithValue(AxiosErrorHandling(response));
    } catch (e) {
      return thunkAPI.rejectWithValue(AxiosErrorHandling(e));
    }
  }
);

export const FetchReportsRunThunk = createAsyncThunk(
  "reports/fetch",
  async (reportName, thunkAPI) => {
    try {
      const response = await ReportsService.fetchReportsRun(reportName);
      if (response.status < HTTPStatus.BAD_REQUEST) {
        return response.data;
      }

      return thunkAPI.rejectWithValue(AxiosErrorHandling(response));
    } catch (e) {
      return thunkAPI.rejectWithValue(AxiosErrorHandling(e));
    }
  }
);

import { MDBBtn, MDBCard, MDBCardBody, MDBCol, MDBContainer, MDBInput, MDBRow } from "mdb-react-ui-kit";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import logo from "../../assets/images/logo.png";
import Spinner from "../../components/Spinner";
import Status from "../../utils/Status";
import { resetPasswordUserSelector, loadResetPasswordStatusSelector, resetPasswordStatusSelector } from "./auth.slice";
import { DecryptUserThunk, ResetPasswordThunk } from "./auth.thunk";
  
export default function ResetPage() {
  const dispatch = useDispatch();
  const { token } = useParams();
  const resetPasswordUser = useSelector(resetPasswordUserSelector);
  const loadResetPasswordStatus = useSelector(loadResetPasswordStatusSelector);
  const resetPasswordStatus = useSelector(resetPasswordStatusSelector);
  const [formData, setFormData] = useState({ password: '', confirmPassword: '' });
  const isSubmitDisabled = !formData.password || formData.password !== formData.confirmPassword || resetPasswordStatus === Status.LOADING || resetPasswordStatus === Status.SUCCEEDED;

  useEffect(() => dispatch(DecryptUserThunk(token)), [token, dispatch]);

  const handleResetPassword = async (event) => {
    event.preventDefault();
    const { id } = resetPasswordUser;
    const { password } = formData;
    dispatch(ResetPasswordThunk({ id, password }));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <MDBContainer fluid className="login-container">
      <header>
        <img src={logo} alt="dta-logo" />
      </header>
      <MDBRow className="mt-5">
        <MDBCol offsetSm="4" size="4">
          <MDBCard style={{ width: "100%" }}>
            <MDBCardBody>
              <div className="text-center">
                {loadResetPasswordStatus === Status.LOADING && <Spinner />}
                {loadResetPasswordStatus === Status.FAILED && (
                  <div>
                    <p className="lead mt-3">User not found. PLease generate a new reset password email and try again.</p>
                    <a href="/" className="btn btn-primary mb-3">Go to Home</a>
                  </div>
                )}
                {loadResetPasswordStatus === Status.SUCCEEDED && (
                  <form onSubmit={handleResetPassword}>
                    <p className="lead">Reset password: {resetPasswordUser.name}</p>
                    {resetPasswordStatus === Status.FAILED && (
                      <div
                        className="alert alert-danger mb-4"
                        role="alert"
                        data-mdb-color="danger"
                      >
                        Error resetting password!
                      </div>
                    )}
                    {resetPasswordStatus === Status.SUCCEEDED && (
                      <div
                        className="alert alert-success mb-4"
                        role="alert"
                        data-mdb-color="success"
                      >
                        Password reset successfully!
                      </div>
                    )}
                    <MDBInput
                      name="password"
                      type="password"
                      label="New password"
                      value={formData.password}
                      onChange={handleInputChange}
                    />
                    <MDBInput
                      name="confirmPassword"
                      type="password"
                      label="Confirm new password"
                      value={formData.confirmPassword}
                      onChange={handleInputChange}
                      className="my-3"
                    />
                    <MDBBtn
                      color="primary"
                      disabled={isSubmitDisabled}
                    >
                      Submit
                    </MDBBtn>
                  </form>
                )}
              </div>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
}

const scipAccountBalanceReport = {
  label: "SCIP Account Balance Report",
  name: "scipAccountBalanceReport",
  parameters: [
    {
      name: "program",
      label: "Program",
      type: "dbfield",
      db: "scip",
      table: "Program",
      keyField: "ProgramId",
      valueField: "Name",
      sort: {
        Name: "asc",
      },
      disabledIfHas: "district",
    },
    {
      name: "divider",
      text: `<div style="display: flex; align-items: center; gap: 10px"><hr style="flex-grow: 1">or<hr style="flex-grow: 1"></div>`,
      type: "note",
    },
    {
      name: "district",
      label: "District",
      type: "dbfield",
      db: "scip",
      table: "District",
      keyField: "DistrictId",
      valueField: "DistrictName",
      sort: {
        DistrictName: "asc",
      },
      disabledIfHas: "program",
    },
    {
      name: "accountType",
      label: "Account Type",
      type: "select",
      options: [
        { key: "all", label: "All" },
        {
          key: "AdministrationExpense",
          label: "Administration/Expense Account",
          showCondition: (params) => !Boolean(params["district"]),
        },
        {
          key: "Reserve",
          label: "Reserve Account",
          showCondition: (params) => !Boolean(params["district"]),
        },
        {
          key: "Revenue/Assessment",
          label: "Revenue/Assessment Account",
          showCondition: (params) => !Boolean(params["program"]),
        },
      ],
    },
    {
      name: "asOfDate",
      label: "As Of Date",
      type: "date",
      defaultValue: null,
    },
  ],
};

export default scipAccountBalanceReport;

import React from "react";

export default function InputField({ type, value, updateValue, ...others }) {
  const onInput = (event) => {
    updateValue(event.target.value);
  };
  return (
    <input
      type={type}
      className="form-control"
      value={value || ''}
      onInput={onInput}
      {...others}
    />
  );
}

import { format, parseISO } from "date-fns";
import { debounce } from "lodash";
import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCheckbox,
  MDBCol,
  MDBDropdown,
  MDBDropdownItem,
  MDBDropdownLink,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBIcon,
  MDBRow,
} from "mdb-react-ui-kit";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import DBField from "../../components/FormElements/DBField";
import FileField from "../../components/FormElements/FileField";
import InputField from "../../components/FormElements/InputField";
import SelectField from "../../components/FormElements/SelectField";
import {
  DecimalField,
  EmailField,
  IntegerField,
  YearField,
} from "../../components/FormElements/Specialized";
import Layout from "../../components/Layout";
import Spinner from "../../components/Spinner";
import simpleReports from "../../reports";
import scipReports from "../../scipReports";
import apportionments from "../../apportionments";
import tools from "../../tools";
import Status from "../../utils/Status";
import { FetchDataThunk } from "./data.thunk";
import ReportsService from "./reports.services";
import { reportsSelector, statusSelector } from "./reports.slice";
import { FetchReportsRunThunk } from "./reports.thunk";
import ReportStatus from "./ReportStatus";

const reports = {
  ...simpleReports,
  ...scipReports,
  ...apportionments,
  ...tools,
};

const types = {
  select: SelectField,
  year: YearField,
  email: EmailField,
  integer: IntegerField,
  decimal: DecimalField,
  number: DecimalField,
  file: FileField,
};

function ParameterType({
  type,
  defaultValue,
  value,
  otherParameters,
  dependsOn,
  table,
  dependsOnHaving,
  disabledIfHas,
  ...others
}) {
  const [dependentValue, setDependentValue] = useState("");
  const [database, setDatabase] = useState(others.db || otherParameters.db);
  const [hasDependent, setHasDependent] = useState(true);
  const [disabledByDependent, setDisabledByDependent] = useState(false);

  const dispatch = useDispatch();
  //eslint-disable-next-line
  const fetchData = useCallback(
    debounce((params) => {
      dispatch(FetchDataThunk(params));
    }, 500),
    []
  );

  useEffect(() => {
    if (disabledIfHas) {
      setDisabledByDependent(Boolean(otherParameters[disabledIfHas]));
    }

    if (type !== "dbfield") {
      return;
    }

    if (otherParameters.db) {
      setDatabase(otherParameters.db);
    }

    if (dependsOn) {
      setDependentValue(otherParameters[dependsOn]);
    }

    if (dependsOnHaving) {
      setHasDependent(dependsOnHaving.includes(otherParameters[dependsOn]));
    }
  }, [otherParameters, type, dependsOn, dependsOnHaving, disabledIfHas]);

  useEffect(() => {
    if (type !== "dbfield") {
      return;
    }

    let parameters = {};
    if (!!dependsOn && dependsOn !== "db") {
      parameters[dependsOn] = dependentValue;
    }

    if (others.where) {
      parameters = { ...parameters, ...others.where };
    }

    others.updateValue(null);

    fetchData({
      database,
      table,
      fields: others.fields || "*",
      joinClause: others.join || {},
      whereClause: parameters,
      sortClause: others.sort || {},
    });
    //eslint-disable-next-line
  }, [dispatch, fetchData, database, dependentValue, dependsOn, type]);

  const isDisabled = others.disabled || (dependsOn && !dependentValue) || (dependsOnHaving && !hasDependent) || (disabledIfHas && disabledByDependent);

  if (type === "dbfield") {
    return (
      <DBField
        {...others}
        value={value || defaultValue}
        type={type}
        entity={table}
        disabled={isDisabled}
      />
    );
  }

  const Component = types[type] ?? InputField;
  const componentOthers = type === 'select' ? { ...others, otherParameters } : others;

  return (
    <Component
      {...componentOthers}
      value={value || defaultValue}
      type={type}
      disabled={isDisabled}
    />
  );
}

function ReportStatusIcon({ status }) {
  switch (status) {
    case ReportStatus.NEW:
      return <i className="fas fa-plus text-green" />;
    case ReportStatus.INWORK:
      return <i className="fas fa-cog fa-spin text-warning" />;
    case ReportStatus.COMPLETED:
      return <i className="fas fa-check text-success" />;
    case ReportStatus.FAILED:
      return <i className="fas fa-exclamation-triangle text-danger" />;
    default:
      return <i className="fas fa-question-circle text-gray" />;
  }
}

function Reports({ data }) {
  const [page, setPage] = useState(1);
  const [size] = useState(5);
  const [maxPage, setMaxPage] = useState(1);
  const [displayData, setDisplayData] = useState([]);

  const downloadElement = (response, report, isLog = false) => {
    const blob = new Blob([response.data]);

    const blobURL =
      window.URL && window.URL.createObjectURL
        ? window.URL.createObjectURL(blob)
        : window.webkitURL.createObjectURL(blob);
    const tempLink = document.createElement("a");
    tempLink.style.display = "none";
    tempLink.href = blobURL;
    tempLink.setAttribute(
      "download",
      isLog ? `${report.name}.log` : report.name
    );

    if (typeof tempLink.download === "undefined") {
      tempLink.setAttribute("target", "_blank");
    }

    document.body.appendChild(tempLink);
    tempLink.click();
    setTimeout(function () {
      document.body.removeChild(tempLink);
      window.URL.revokeObjectURL(blobURL);
    }, 200);
  };

  const downloadReport = (report) => {
    return async () => {
      const response = await ReportsService.downloadReport(report.id);
      downloadElement(response, report);
    };
  };

  const downloadReportLog = (report) => {
    return async () => {
      const response = await ReportsService.logReport(report.id);
      downloadElement(response, report, true);
    };
  };

  const changePage = (direction = 1) => {
    return () => {
      let newPage = page + direction * 1;
      if (newPage < 1) {
        newPage = 1;
      } else if (newPage > maxPage) {
        newPage = maxPage;
      }

      setPage(newPage);
    };
  };

  useEffect(() => {
    const startItem = (page - 1) * size;
    const endItem = page * size;

    setDisplayData(
      data.slice(startItem, endItem > data.length ? data.length : endItem)
    );
  }, [data, page, size]);

  useEffect(() => {
    setMaxPage(Math.ceil(data.length / size));
  }, [data, size]);

  return (
    <table className="reports-table table table-striped">
      <thead>
        <tr>
          <th className="reports-table-run-date">Report Info</th>
          <th className="reports-table-status text-center">Status</th>
          <th className="reports-table-user">Requested By</th>
          <th className="reports-table-file text-center">Action</th>
        </tr>
      </thead>
      <tbody>
        {displayData.map((report) => (
          <tr key={`previous-report-${report.id}`}>
            <td className="reports-table-run-date">
              <strong>Report Id:</strong> {report.id}
              <br />
              <strong>Report Name:</strong>{" "}
              {report.status === ReportStatus.COMPLETED ? report.name : "-"}
              <br />
              <strong>Date:</strong>{" "}
              {format(parseISO(report.date), "MM/dd/yyyy hh:mm a")}
              <br />
              <strong>Status:</strong> {report.status}
            </td>
            <td className="reports-table-status text-center">
              <ReportStatusIcon status={report.status} />
            </td>
            <td className="reports-table-user">{report.requester.name}</td>
            <td className="reports-table-file text-center">
              {report.status === ReportStatus.COMPLETED ? (
                <MDBDropdown className="mx-auto">
                  <MDBDropdownToggle size="sm">Download</MDBDropdownToggle>
                  <MDBDropdownMenu>
                    <MDBDropdownItem>
                      <MDBDropdownLink
                        href="#"
                        onClick={downloadReport(report)}
                      >
                        Download {report.name}
                      </MDBDropdownLink>
                    </MDBDropdownItem>
                    <MDBDropdownItem>
                      <MDBDropdownLink
                        href="#"
                        onClick={downloadReportLog(report)}
                      >
                        Download Log
                      </MDBDropdownLink>
                    </MDBDropdownItem>
                  </MDBDropdownMenu>
                </MDBDropdown>
              ) : report.status === ReportStatus.FAILED ? (
                report.failReason || "Check app logs"
              ) : (
                "-"
              )}
            </td>
          </tr>
        ))}
      </tbody>
      <tfoot>
        <tr>
          <td colSpan="2">
            Page: {page} / {maxPage}
          </td>
          <td colSpan="2">
            <nav aria-label="Page navigation example">
              <ul className="pagination mb-0 justify-content-end">
                <li
                  className={`page-item ${page === 1 ? "disabled" : ""}`.trim()}
                >
                  <button
                    className="page-link"
                    onClick={changePage(-1)}
                    disabled={page === 1}
                  >
                    <span className="icon me-2">
                      <MDBIcon icon="chevron-left" fas />
                    </span>
                    <span className="text">Previous</span>
                  </button>
                </li>
                <li
                  className={`page-item ${
                    page === maxPage ? "disabled" : ""
                  }`.trim()}
                >
                  <button
                    className="page-link"
                    onClick={changePage(1)}
                    disabled={page === maxPage}
                  >
                    <span className="text">Next</span>
                    <span className="icon ms-2">
                      <MDBIcon icon="chevron-right" fas />
                    </span>
                  </button>
                </li>
              </ul>
            </nav>
          </td>
        </tr>
      </tfoot>
    </table>
  );
}

export default function ReportPage({ path }) {
  const [report, setReport] = useState(null);
  const [parameters, setParameters] = useState({});
  const [notification, setNotification] = useState(false);
  const [autoRefresh, setAutoRefresh] = useState(false);
  const { reportKey } = useParams();
  const dispatch = useDispatch();
  const [timeout, setTimeout] = useState(null);

  const ranReports = useSelector(reportsSelector);
  const status = useSelector(statusSelector);

  const runRefresh = () => {
    dispatch(FetchReportsRunThunk(report.name));
  };

  const runReport = () => {
    if (!report) {
      return;
    }

    ReportsService.runReport(report.name, parameters);
    setNotification(true);
    setTimeout(() => {
      setNotification(false);
    }, 2000);
    setTimeout(() => {
      runRefresh();
    }, 500);
  };

  useEffect(() => {
    const selectedReport = reports[reportKey];
    dispatch(FetchReportsRunThunk(reportKey));
    setReport(selectedReport);
    setParameters(
      selectedReport.parameters.reduce(
        (accum, parameter) => ({
          ...accum,
          [parameter.name]: parameter.defaultValue,
        }),
        {}
      )
    );
  }, [reportKey, dispatch]);

  const setParameterValue = (parameterName) => {
    return (value) => {
      setParameters((prevParameters) => ({ ...prevParameters, [parameterName]: value }));
    };
  };

  const changeAutoRefresh = (event) => {
    setAutoRefresh(event.target.checked);
    if (event.target.checked) {
      setTimeout(
        setInterval(() => {
          runRefresh();
        }, 5000)
      );
    } else {
      console.log(timeout);
      clearInterval(timeout);
      setTimeout(null);
    }
  };

  if (!report) {
    return <Spinner isFullScreen />;
  }

  return (
    <Layout path={path}>
      <MDBRow>
        <MDBCol size="12">
          <MDBCard style={{ width: "100%" }}>
            <MDBCardBody>
              <MDBRow>
                <MDBCol size="5">
                  <h3 className="d-flex justify-content-between mb-2">
                    <span>Running Report</span>
                    <MDBBtn onClick={runReport}>
                      <span className="me-2">
                        <MDBIcon icon="play" fas />
                      </span>
                      Run Report
                    </MDBBtn>
                  </h3>

                  {notification && (
                    <div className="alert alert-success mt-4">
                      Report triggered successfully.
                    </div>
                  )}

                  <div className="mb-2 mt-3">
                    <strong>Report Name:</strong> <span>{report.label}</span>
                  </div>

                  <div className="">
                    {report.parameters.map((parameter) => (
                      <div
                        className="form-group"
                        key={`parameter-${parameter.name}`}
                      >
                        {parameter.type !== "note" && <label
                          htmlFor={`parameter-${parameter.name}`}
                          className="form-label"
                        >
                          {parameter.label}
                        </label>}
                        {parameter.type === "note" ? (
                          <div
                            dangerouslySetInnerHTML={{ __html: parameter.text }}
                          />
                        ) : (
                          <ParameterType
                            id={`parameter-${parameter.name}`}
                            type={parameter.type || "text"}
                            value={parameters[parameter.name]}
                            updateValue={setParameterValue(parameter.name)}
                            {...parameter}
                            otherParameters={parameters}
                          />
                        )}
                      </div>
                    ))}
                  </div>
                </MDBCol>
                <MDBCol size="7">
                  <div className="d-flex justify-content-between mb-2 align-items-center">
                    <h3>Previous runs</h3>

                    <div className="d-flex align-items-center">
                      <MDBCheckbox
                        name="autoRefresh"
                        value=""
                        id="autoRefresh"
                        label="Auto Refresh"
                        wrapperClass="me-3"
                        defaultChecked={autoRefresh}
                        onChange={changeAutoRefresh}
                      />
                      <MDBBtn onClick={runRefresh}>
                        <span className="me-2">
                          <MDBIcon icon="sync" fas />
                        </span>
                        <span>Refresh</span>
                      </MDBBtn>
                    </div>
                  </div>

                  {status === Status.LOADING ? (
                    <Spinner isFullContent />
                  ) : (
                    <Reports data={ranReports} />
                  )}
                </MDBCol>
              </MDBRow>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    </Layout>
  );
}

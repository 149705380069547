import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCol,
  MDBContainer,
  MDBInput,
  MDBRow,
} from "mdb-react-ui-kit";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import logo from "../../assets/images/logo.png";
import Status from "../../utils/Status";
import { statusSelector, isResettingSelector, resetStatusSelector, toggleIsResetting, clearResetStatus } from "./auth.slice";
import { LoginUserThunk, ResetRequestThunk } from "./auth.thunk";

export default function LoginPage() {
  const dispatch = useDispatch();
  const loginStatus = useSelector(statusSelector);
  const isResetting = useSelector(isResettingSelector);
  const resetStatus = useSelector(resetStatusSelector);
  const [isResetDisabled, setIsResetDisabled] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    dispatch(
      LoginUserThunk({
        username: formData.get("username"),
        password: formData.get("password"),
      })
    );
  };

  const handleResetPassword = (event) => {
    event.preventDefault();
    setIsResetDisabled(true);
    const formData = new FormData(event.currentTarget);
    dispatch(ResetRequestThunk(formData.get('identifier')));    
  };

  useEffect(() => {
    if (isResetting) return;
    setIsResetDisabled(false);
    const secondsToShowResetMessage = 10;
    const clearResetStatusTimeout = setTimeout(() => dispatch(clearResetStatus()), 1000 * secondsToShowResetMessage);
    return () => clearTimeout(clearResetStatusTimeout);
  }, [isResetting, dispatch]);  

  return (
    <MDBContainer fluid className="login-container">
      <header>
        <img src={logo} alt="dta-logo" />
      </header>

      <MDBRow className="mt-5">
        <MDBCol offsetSm="4" size="4">
          <MDBCard style={{ width: "100%" }}>
            <MDBCardBody>
              <form onSubmit={handleSubmit}>
                <MDBCardTitle className="d-flex justify-content-center">
                  Sign In
                </MDBCardTitle>
                {loginStatus === Status.FAILED && (
                  <div
                    className="alert alert-danger"
                    role="alert"
                    data-mdb-color="danger"
                  >
                    User or password not valid!
                  </div>
                )}
                <div className="mb-3">
                  <MDBInput
                    label="Username"
                    id="login-username"
                    name="username"
                    type="text"
                    className="mt-3"
                  />
                  <MDBInput
                    label="Password"
                    id="login-password"
                    name="password"
                    type="password"
                    className="mt-3"
                  />
                </div>
                <MDBBtn block type="submit">
                  Sign In
                </MDBBtn>
              </form>
              <hr/>
              <MDBBtn
                block={!isResetting}
                type="button"
                color="secondary"
                className={isResetting ? 'float-end' : ''}
                onClick={() => dispatch(toggleIsResetting())}
              >
                { isResetting ? 'Cancel' : 'Forgot password' }
              </MDBBtn>
              {isResetting && (
                <form onSubmit={handleResetPassword}>
                  <div className="mt-4 mb-3">Forgot your password?</div>
                  <label htmlFor="identifier" style={{ fontSize: "14px", color: "gray" }}>Enter the username or email address associated with your account to send a password reset email.</label>
                  <MDBInput id="identifier" name="identifier" type="text" color="warning" className="mt-1 mb-3"/>
                  <MDBBtn block type="submit" color="dark" disabled={isResetDisabled}>
                    Reset password
                  </MDBBtn>
                </form>
              )}
              {resetStatus === Status.SUCCEEDED && (
                <div
                  className="alert alert-success mt-3 mb-0"
                  role="alert"
                  data-mdb-color="success"
                >
                  Reset email sent successfully
                </div>
              )}
              {resetStatus === Status.FAILED && (
                <div
                  className="alert alert-danger mt-3 mb-0"
                  role="alert"
                  data-mdb-color="danger"
                >
                  No user found
                </div>
              )}
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
}

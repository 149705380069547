const pfgMonthlyBalanceReport = {
    label: "PFG Monthly Balance Report",
    name: "pfgMonthlyBalanceReport",
    parameters: [
        {
            name: "bondcall",
            label: "Bond Call Date",
            type: "date",
        },
        {
            name: "agency",
            label: "Agency",
            type: "select",
            defaultValue: "PF",
            options: [
            { key: "PF", label: "PF" },
            { key: "PFW", label: "PFW" },
            { key: "PFLA", label: "PFLA" },
            { key: "PFG FL", label: "PFG FL" },
            { key: "PFMO", label: "PFMO" },
            ],
        },
    ],
};
  
export default pfgMonthlyBalanceReport;
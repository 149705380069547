import { createAsyncThunk } from "@reduxjs/toolkit";
import HTTPStatus from "../../utils/HTTPStatus";
import AuthService from "./auth.services";
import AxiosErrorHandling from "../../utils/AxiosErrorHandling";
import AxiosService from "../../utils/AxiosService";

export const LoginUserThunk = createAsyncThunk(
  "auth/login",
  async (props, thunkAPI) => {
    try {
      const { username, password, rememberMe } = props;
      const response = await AuthService.authenticate(
        username,
        password,
        rememberMe || true
      );
      if (response.status < HTTPStatus.BAD_REQUEST) {
        return response.data;
      }

      return thunkAPI.rejectWithValue(AxiosErrorHandling(response));
    } catch (e) {
      return thunkAPI.rejectWithValue(AxiosErrorHandling(e));
    }
  }
);

export const InitialUserFetch = createAsyncThunk(
  "auth/me",
  async (props, thunkAPI) => {
    try {
      if (!AxiosService.hasToken()) {
        return thunkAPI.rejectWithValue("No token");
      }
      const response = await AuthService.fetchUser();
      if (response.status < HTTPStatus.BAD_REQUEST) {
        return response.data;
      }

      return thunkAPI.rejectWithValue(AxiosErrorHandling(response));
    } catch (e) {
      return thunkAPI.rejectWithValue(AxiosErrorHandling(e));
    }
  }
);

export const ResetRequestThunk = createAsyncThunk(
  "auth/resetRequest",
  async (identifier, thunkAPI) => {
    try {
      const response = await AuthService.resetRequest(identifier);
      if (response.status < HTTPStatus.BAD_REQUEST) return response.data;
      return thunkAPI.rejectWithValue(AxiosErrorHandling(response));
    } catch (e) {
      return thunkAPI.rejectWithValue(AxiosErrorHandling(e));
    }
  }
)

export const DecryptUserThunk = createAsyncThunk(
  "auth/decryptUser",
  async (token, thunkAPI) => {
    try {
      const response = await AuthService.decryptUser(token);
      if (response.status < HTTPStatus.BAD_REQUEST) return response.data;
      return thunkAPI.rejectWithValue(AxiosErrorHandling(response));
    } catch (e) {
      return thunkAPI.rejectWithValue(AxiosErrorHandling(e));
    }
  }
)

export const ResetPasswordThunk = createAsyncThunk(
  "auth/resetPassword",
  async (data, thunkAPI) => {
    try {
      const response = await AuthService.resetPassword(data);
      if (response.status < HTTPStatus.BAD_REQUEST) return response.data;
      return thunkAPI.rejectWithValue(AxiosErrorHandling(response));
    } catch (e) {
      return thunkAPI.rejectWithValue(AxiosErrorHandling(e));
    }
  }
)

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import Spinner from "./components/Spinner";
import UserPage from "./pages/admin/User";
import { useAuth } from "./pages/auth/auth.hooks";
import { statusSelector } from "./pages/auth/auth.slice";
import { InitialUserFetch } from "./pages/auth/auth.thunk";
import LoginPage from "./pages/auth/Login";
import ResetPage from "./pages/auth/Reset";
import DashboardPage from "./pages/dashboard/Dashboard";
import DevelopmentPage from "./pages/development/Development";
import ReportPage from "./pages/reports/Report";
import ReportsList from "./pages/reports/ReportsList";
import Status from "./utils/Status";

function AuthenticatedRoute(props) {
  const { isAuthenticated, isAdmin } = useAuth();
  if (!isAuthenticated || (isAuthenticated && props.isAdmin && !isAdmin())) {
    return <Redirect to="/auth/login" />;
  }

  return props.children;
}

function RouterContainer() {
  const status = useSelector(statusSelector);
  const { isAuthenticated } = useAuth();

  if (status === Status.INITIAL_LOADING) {
    return <Spinner isFullScreen />;
  }

  return (
    <Router>
      <Switch>
        <Route path="/" exact>
          <Redirect to="/auth/login" />
        </Route>
        <Route path="/auth" exact>
          <Redirect to="/auth/login" />
        </Route>
        <Route path="/auth/login" exact>
          {isAuthenticated ? <Redirect to="/dashboard" /> : <LoginPage />}
        </Route>
        <Route path="/auth/reset/:token" exact>
          <ResetPage />
        </Route>
        <Route path="/dashboard" exact>
          <AuthenticatedRoute>
            <DashboardPage path="/dashboard" />
          </AuthenticatedRoute>
        </Route>
        <Route path="/dev" exact>
          <AuthenticatedRoute isAdmin>
            <DevelopmentPage path="/dev" />
          </AuthenticatedRoute>
        </Route>
        <Route path="/list" exact>
          <AuthenticatedRoute isAdmin>
            <ReportsList path="/list" />
          </AuthenticatedRoute>
        </Route>
        <Route path="/users" exact>
          <AuthenticatedRoute isAdmin>
            <UserPage path="/users" />
          </AuthenticatedRoute>
        </Route>
        <Route path="/reports/:reportKey" exact>
          <AuthenticatedRoute>
            <ReportPage path="/reports" />
          </AuthenticatedRoute>
        </Route>
        <Route path="/apportionments/:reportKey" exact>
          <AuthenticatedRoute>
            <ReportPage path="/apportionments" />
          </AuthenticatedRoute>
        </Route>
        <Route path="/tools/:reportKey" exact>
          <AuthenticatedRoute>
            <ReportPage path="/tools" />
          </AuthenticatedRoute>
        </Route>
      </Switch>
    </Router>
  );
}

function App() {
  const dispatch = useDispatch();
  dispatch(InitialUserFetch());

  return <RouterContainer />;
}

export default App;

import { format, parseISO } from "date-fns";

const backupGeneratorReport = {
  label: "CADF Generator",
  name: "backupGeneratorReport",
  parameters: [
    {
      name: "contract",
      label: "HERO ID/Contract ID",
      type: "dbfield",
      defaultValue: "",
      table: "AssessmentContract",
      db: "hero",
      keyField: "AssessmentContractCode",
      valueField: "AssessmentContractCode",
      fields: ["AssessmentContractCode"],
    },
    {
      name: "payoffQuoteId",
      label: "Payoff Quote Id",
      type: "dbfield",
      defaultValue: "",
      table: "PayoffQuote",
      db: "hero",
      keyField: "QuoteId",
      valueField: (row) =>
        `${row.QuoteId} (${format(parseISO(row.BondCallDate), "MM/dd/yyyy")}) ${
          row.PrepaymentAmount
        }`,
      fields: ["QuoteId", "BondCallDate", "PrepaymentAmount"],
    },
    {
      name: "note",
      text: `<h1>Report Information</h1>
      <p>CADF information is generated for a specific HERO ID, Contract ID, or Applicant ID as well as the assessment payment schedule and a bond payment schedule if applicable.
      Adding a QuoteId will fetch an additional payment schedule corresponding to that id. This schedule will appear in the exported excel file as a tab labeled with that id.</p>`,
      type: "note",
    },
  ],
};

export default backupGeneratorReport;

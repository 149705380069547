import AxiosService from "../../utils/AxiosService";

const authenticate = (username, password, rememberMe = false) => {
  return AxiosService.post("/auth/login", {
    username,
    password,
    rememberMe,
  });
};
const fetchUser = () => {
  return AxiosService.get("/auth/me");
};
const resetRequest = (identifier) => {
  return AxiosService.post("/auth/resetRequest", identifier);
};
const decryptUser = (token) => {
  return AxiosService.post("/auth/decryptUser", token);
};
const resetPassword = (data) => {
  return AxiosService.post("/auth/resetPassword", data);
};

const AuthService = {
  authenticate,
  fetchUser,
  resetRequest,
  decryptUser,
  resetPassword,
};

export default AuthService;

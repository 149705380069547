const prepaymentAddendumRenameTool = {
  label: "Prepayment Addendum Renamer",
  name: "prepaymentAddendumRenameTool",
  parameters: [
    {
      name: "folder",
      label: "Folder",
      type: "text",
    },
    {
      name: "note",
      text: `<h1>READ First</h1>
      <p>This tool accesses the same client that Cloudberry uses: S3 and will look for pdfs under a specific folder in the <strong>taussig-client/CSCDA/PACE/Prepayments/Addendums</strong> folder.
      So for example if you want all the prepayment addendums in <strong>taussig-client/CSCDA/PACE/Prepayments/Addendums/2019 10 17</strong> renamed, you should input into the form <strong><em>2019 10 17</em></strong> the renamed pdfs will be then delivered as a zip folder</p>`,
      type: "note",
    },
  ],
};

export default prepaymentAddendumRenameTool;

import { createSlice } from "@reduxjs/toolkit";
import AxiosService from "../../utils/AxiosService";
import Status from "../../utils/Status";
import { InitialUserFetch, LoginUserThunk, ResetRequestThunk, DecryptUserThunk, ResetPasswordThunk } from "./auth.thunk";

const initialState = {
  isAuthenticated: false,
  user: null,
  status: Status.INITIAL_LOADING,
  error: null,
  isResetting: false,
  resetStatus: Status.IDLE,
  resetPasswordUser: {},
  loadResetPasswordStatus: Status.IDLE,
  resetPasswordStatus: Status.IDLE,
};  

export const authSlice = createSlice({
  name: "Auth",
  initialState,
  reducers: {
    logoutUser(state) {
      state.isAuthenticated = false;
      state.user = null;
      state.status = Status.IDLE;
      AxiosService.removeAuthenticationToken();
    },
    toggleIsResetting(state) {
      state.isResetting = !state.isResetting;
      state.resetStatus = Status.IDLE;
    },
    clearResetStatus(state) {
      state.resetStatus = Status.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(LoginUserThunk.fulfilled, (state, action) => {
        const { payload } = action;
        if (payload) {
          state.status = Status.IDLE;
          state.isAuthenticated = true;
          state.user = payload.user;
          AxiosService.storeAuthenticationToken(payload.token);
        }
      })
      .addCase(LoginUserThunk.pending, (state) => {
        state.status = Status.LOADING;
        state.isAuthenticated = false;
      })
      .addCase(LoginUserThunk.rejected, (state, { payload }) => {
        state.status = Status.FAILED;
        state.error = payload;
        state.isAuthenticated = false;
      });
    builder
      .addCase(InitialUserFetch.fulfilled, (state, action) => {
        const { payload } = action;
        if (payload) {
          state.status = Status.IDLE;
          state.isAuthenticated = true;
          state.user = payload;
        }
      })
      .addCase(InitialUserFetch.pending, (state) => {
        state.status = Status.INITIAL_LOADING;
        state.isAuthenticated = false;
      })
      .addCase(InitialUserFetch.rejected, (state, { payload }) => {
        state.status = Status.IDLE;
        state.isAuthenticated = false;
        AxiosService.removeAuthenticationToken();
      });
    builder
      .addCase(ResetRequestThunk.fulfilled, (state) => {
        state.resetStatus = Status.SUCCEEDED;
        state.isResetting = false;
      })
      .addCase(ResetRequestThunk.pending, (state) => {
        state.resetStatus = Status.LOADING;
      })
      .addCase(ResetRequestThunk.rejected, (state) => {
        state.resetStatus = Status.FAILED;
        state.isResetting = false;
      });
    builder
      .addCase(DecryptUserThunk.fulfilled, (state, { payload: user }) => {
        state.resetPasswordUser = user;
        state.loadResetPasswordStatus = Status.SUCCEEDED;
      })
      .addCase(DecryptUserThunk.pending, (state) => {
        state.loadResetPasswordStatus = Status.LOADING;
      })
      .addCase(DecryptUserThunk.rejected, (state) => {
        state.loadResetPasswordStatus = Status.FAILED;
      });
    builder
      .addCase(ResetPasswordThunk.fulfilled, (state) => {
        state.resetPasswordStatus = Status.SUCCEEDED;
        setTimeout(() => window.location.href = '/', 1500);
      })
      .addCase(ResetPasswordThunk.pending, (state) => {
        state.resetPasswordStatus = Status.LOADING;
      })
      .addCase(ResetPasswordThunk.rejected, (state) => {
        state.resetPasswordStatus = Status.FAILED;
      });
  },
});

export default authSlice.reducer;

export const { logoutUser, toggleIsResetting, clearResetStatus } = authSlice.actions;

// selectors
export const statusSelector = (state) => state.Auth.status;
export const isAuthenticatedSelector = (state) => state.Auth.isAuthenticated;
export const userSelector = (state) => state.Auth.user;
export const isResettingSelector = (state) => state.Auth.isResetting;
export const resetStatusSelector = (state) => state.Auth.resetStatus;
export const resetPasswordUserSelector = (state) => state.Auth.resetPasswordUser;
export const loadResetPasswordStatusSelector = (state) => state.Auth.loadResetPasswordStatus;
export const resetPasswordStatusSelector = (state) => state.Auth.resetPasswordStatus;

import AxiosService from "../../utils/AxiosService";

const fetchAllUsers = () => {
  return AxiosService.get(`/admin/users/`);
};

const fetchUser = (userId) => {
  return AxiosService.get(`/admin/users/${userId}`);
};

const createUser = (userInfo) => {
  return AxiosService.post(`/admin/users/`, userInfo);
};

const updateUser = (userId, userInfo) => {
  return AxiosService.put(`/admin/users/${userId}`, userInfo);
};

const deleteUser = (userId) => {
  return AxiosService.delete(`/admin/users/${userId}`);
};

const UsersService = {
  fetchAllUsers,
  fetchUser,
  createUser,
  updateUser,
  deleteUser,
};

export default UsersService;

import AxiosService from "../../utils/AxiosService";

const downloadAppLog = () => {
  return AxiosService.get(`/admin/logs/app`, {
    responseType: "arraybuffer",
  });
};

const downloadCeleryLog = () => {
  return AxiosService.get(`/admin/logs/celery`, {
    responseType: "arraybuffer",
  });
};

const AdminService = {
  downloadAppLog,
  downloadCeleryLog,
};

export default AdminService;

import React from "react";
import InputField from "./InputField";

export function YearField(props) {
  return <InputField type="number" min={1900} max={2200} step={1} {...props} />;
}

export function IntegerField(props) {
  return <InputField type="number" step={1} {...props} />;
}

export function DecimalField(props) {
  return <InputField type="number" step={0.01} {...props} />;
}

export function EmailField(props) {
  return <InputField type="email" {...props} />;
}

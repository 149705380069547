import { MDBBtn, MDBCard, MDBCardBody, MDBCol, MDBRow } from "mdb-react-ui-kit";
import React, { useState } from "react";
import Layout from "../../components/Layout";
import Editor from "react-simple-code-editor";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-json";

import reports from "../../reports";
import AdminService from "../admin/admin.services";
import ReportsService from "../reports/reports.services";

export default function DevelopmentPage({ path }) {
  const [code, setCode] = useState(`{
}`);
  const [report, setReport] = useState();
  const [notification, setNotification] = useState(false);

  const onReportChange = (event) => {
    setReport(event.target.value);
    const reportInfo = reports[event.target.value];

    setCode(`{
${reportInfo.parameters
  .map((parameter) => `  "${parameter.name}": ""`)
  .join(",\n")}
}`);
  };

  const runReport = () => {
    if (!report) {
      return;
    }

    ReportsService.runReportSoftCode(report.name, code);
    setNotification(true);
    setTimeout(() => {
      setNotification(false);
    }, 2000);
  };

  const downloadFile = (response, what) => {
    const blob = new Blob([response.data]);

    const blobURL =
      window.URL && window.URL.createObjectURL
        ? window.URL.createObjectURL(blob)
        : window.webkitURL.createObjectURL(blob);
    const tempLink = document.createElement("a");
    tempLink.style.display = "none";
    tempLink.href = blobURL;
    tempLink.setAttribute("download", what);

    if (typeof tempLink.download === "undefined") {
      tempLink.setAttribute("target", "_blank");
    }

    document.body.appendChild(tempLink);
    tempLink.click();
    setTimeout(function () {
      document.body.removeChild(tempLink);
      window.URL.revokeObjectURL(blobURL);
    }, 200);
  };

  const getAppLog = async () => {
    const response = await AdminService.downloadAppLog();
    downloadFile(response, "app.log");
  };
  const getCeleryLog = async () => {
    const response = await AdminService.downloadCeleryLog();
    downloadFile(response, "celery.log");
  };

  return (
    <Layout path={path}>
      <MDBRow>
        <MDBCol size="12">
          <MDBCard style={{ width: "100%" }}>
            <MDBCardBody>
              <h3 className="d-flex justify-content-between">
                <span>Report Selector</span>
                <MDBBtn onClick={runReport}>Run Report</MDBBtn>
              </h3>

              <MDBRow>
                <MDBCol size="4">
                  <div className="form-outline">
                    <label className="form-label" htmlFor="report-selector">
                      Report
                    </label>
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      id="report-selector"
                      onChange={onReportChange}
                    >
                      <option>Select a report from this dropdown</option>
                      {Object.values(reports).map((reportInfo) => (
                        <option
                          value={reportInfo.name}
                          key={`report-${reportInfo.name}`}
                        >
                          {reportInfo.label}
                        </option>
                      ))}
                    </select>
                  </div>
                  <ul>
                    <li className="mb-2 mt-2">
                      <MDBBtn onClick={getAppLog}>Download app.log</MDBBtn>
                    </li>
                    <li>
                      <MDBBtn onClick={getCeleryLog}>
                        Download celery.log
                      </MDBBtn>
                    </li>
                  </ul>
                </MDBCol>
                <MDBCol size="8">
                  {notification && (
                    <div className="alert alert-success mt-4">
                      Report triggered successfully.
                    </div>
                  )}

                  <div className="form-outline">
                    <label className="form-label" htmlFor="report-editor">
                      Parameters
                    </label>
                    <Editor
                      value={code}
                      id="report-editor"
                      onValueChange={setCode}
                      highlight={(code) => highlight(code, languages.json)}
                      padding={10}
                      style={{
                        fontFamily: '"Fira code", "Fira Mono", monospace',
                        fontSize: 12,
                      }}
                    />
                  </div>
                </MDBCol>
              </MDBRow>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    </Layout>
  );
}

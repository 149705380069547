const outstandingPayoffRequestsReport = {
    label: "Outstanding Payoff Requests Report",
    name: "outstandingPayoffRequestsReport",
    parameters: [
      {
        name: "db",
        label: "Database",
        type: "select",
        defaultValue: "hero",
        options: [
          { key: "hero", label: "HERO" },
          { key: "data", label: "DATA" },
        ],
      },
    ],
  };
  
  export default outstandingPayoffRequestsReport;
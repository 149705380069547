const solanoApportionmentReport = {
  label: "Solano Apportionment - HERO",
  name: "solanoApportionmentReport",
  parameters: [
    {
      name: "fundNum",
      label: "Fund Name",
      type: "dbfield",
      defaultValue: "",
      db: "hero",
      table: "Levy",
      keyField: "CountyFundNumberId",
      valueField: (row) =>
        `${row.Name} (${row.CountyFundNumberId}) ${row.TaxYear}`,
      fields: ["Name", "CountyFundNumberId", "TaxYear"],
      where: {
        CountyFundNumberId: {
          null: false,
        },
      },
      sort: {
        TaxYear: "asc",
      },
    },
    {
      name: "taxYear",
      label: "Tax year",
      type: "decimal",
    },
    {
      name: "countyFee",
      label: "County Fee",
      type: "decimal",
    },
    {
      name: "countyDataAmount",
      label: "County Data Amount",
      type: "decimal",
    },
    {
      name: "chargesAdjustmentAmount",
      label: "Charges Adjustment Amount",
      type: "decimal",
    },
    {
      name: "apportionmentPercent",
      label: "Apportionment Percent (%)",
      type: "integer",
    },
    {
      name: "file",
      label: "Excel file upload",
      type: "file",
      accept:
        ".xls,.xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel",
    },
  ],
};

export default solanoApportionmentReport;

import { createSlice } from "@reduxjs/toolkit";
import Status from "../../utils/Status";
import { FetchReportsRunThunk, FetchReportsThunk } from "./reports.thunk";
import { compareDesc, formatISO, parseISO } from "date-fns";

const initialState = {
  reports: [],
  status: Status.INITIAL_LOADING,
  error: null,
};

export const reportsSlice = createSlice({
  name: "Reports",
  initialState,
  reducers: {
    cleanupReports(state) {
      state.reports = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(FetchReportsRunThunk.fulfilled, (state, action) => {
        const { payload } = action;
        if (payload) {
          state.status = Status.IDLE;
          state.reports = payload.map((reportRun) => ({
            ...reportRun,
            date: parseISO(reportRun.date),
          }));
          state.reports.sort((a, b) => compareDesc(a.date, b.date));
          state.reports = state.reports.map((reportRun) => ({
            ...reportRun,
            date: formatISO(reportRun.date),
          }));
        }
      })
      .addCase(FetchReportsRunThunk.pending, (state) => {
        state.status = Status.LOADING;
        state.reports = [];
      })
      .addCase(FetchReportsRunThunk.rejected, (state, { payload }) => {
        state.status = Status.FAILED;
        state.error = payload;
      });
    builder
      .addCase(FetchReportsThunk.fulfilled, (state, action) => {
        const { payload } = action;
        if (payload) {
          state.status = Status.IDLE;
          state.reports = payload.map((reportRun) => ({
            ...reportRun,
            date: parseISO(reportRun.date),
          }));
          state.reports.sort((a, b) => compareDesc(a.date, b.date));
          state.reports = state.reports.map((reportRun) => ({
            ...reportRun,
            date: formatISO(reportRun.date),
          }));
        }
      })
      .addCase(FetchReportsThunk.pending, (state) => {
        state.status = Status.LOADING;
        state.reports = [];
      })
      .addCase(FetchReportsThunk.rejected, (state, { payload }) => {
        state.status = Status.FAILED;
        state.error = payload;
      });
  },
});

export default reportsSlice.reducer;

export const { cleanupReports } = reportsSlice.actions;

// selectors
export const statusSelector = (state) => state.Reports.status;
export const reportsSelector = (state) => state.Reports.reports;

const mcedDebtServiceReport = {
  name: "mcedDebtServiceReport",
  label: "MCED Debt Service Report",
  parameters: [
    {
      name: "bondcall",
      label: "BondCall",
      type: "date",
    },
  ],
};

export default mcedDebtServiceReport;

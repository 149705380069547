import axios from "axios";

const baseURL = process.env.REACT_APP_HOME_URL;

const headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

const instance = axios.create({
  baseURL,
  headers,
});

const setAuthenticationToken = (token) => {
  headers["Authorization"] = token ? `Bearer ${token}` : undefined;
  instance.defaults.headers = { ...headers };
};

setAuthenticationToken(localStorage.getItem("dta:token"));

const AxiosService = {
  request(config) {
    return instance.request(config);
  },
  get(url, config) {
    return instance.get(url, config);
  },
  delete(url, config) {
    return instance.delete(url, config);
  },
  head(url, config) {
    return instance.head(url, config);
  },
  options(url, config) {
    return instance.options(url, config);
  },
  post(url, data, config) {
    return instance.post(url, data, config);
  },
  put(url, data, config) {
    return instance.put(url, data, config);
  },
  patch(url, data, config) {
    return instance.patch(url, data, config);
  },
  setAuthenticationToken,
  storeAuthenticationToken(token) {
    if (token) {
      localStorage.setItem("dta:token", token);
      setAuthenticationToken(token);
    }
  },
  removeAuthenticationToken() {
    localStorage.removeItem("dta:token");
    setAuthenticationToken(null);
  },
  hasToken() {
    return !!localStorage.getItem("dta:token");
  },
};

export default AxiosService;

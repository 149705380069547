const dailyQuoteReport = {
  label: "CA1st Daily Quote Report",
  name: "dailyQuoteReport",
  parameters: [
    {
      name: "date",
      label: "Date",
      type: "date",
    },
  ],
};

export default dailyQuoteReport;

const enrollmentDetailReport = {
  label: "Enrollment Detail Report",
  name: "enrollmentDetailReport",
  parameters: [
    {
      name: "db",
      label: "Database",
      type: "select",
      defaultValue: "data",
      options: [
        { key: "data", label: "data" },
        { key: "hero", label: "hero" },
      ],
    },
    {
      name: "fundNum",
      label: "Fund Name",
      type: "dbfield",
      defaultValue: "",
      table: "Levy",
      keyField: "CountyFundNumberId",
      valueField: (row) =>
        `${row.Name} (${row.CountyFundNumberId}) ${row.TaxYear}`,
      fields: ["Name", "CountyFundNumberId", "TaxYear"],
      where: {
        CountyFundNumberId: {
          null: false,
        },
      },
      sort: {
        TaxYear: "asc",
      },
      dependsOn: "db",
    },
    {
      name: "taxYear",
      label: "Tax year",
      type: "decimal",
    },
    {
      name: "status",
      label: "Status",
      type: "select",
      defaultValue: "Enrolled",
      options: [
        { key: "Enrolled", label: "Enrolled" },
        { key: "Testing", label: "Testing" },
      ],
    },
  ],
};

export default enrollmentDetailReport;

import {
  MDBCollapse,
  MDBContainer,
  MDBDropdown,
  MDBDropdownItem,
  MDBDropdownLink,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBIcon,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBNavbarNav,
  MDBNavbarToggler,
} from "mdb-react-ui-kit";
import React, { useState } from "react";
import logo from "../assets/images/logo.png";
import { useAuth } from "../pages/auth/auth.hooks";

import reports from "../reports";
import scipReports from "../scipReports";
import apportionments from "../apportionments";
import tools from "../tools";

export default function Layout({ path, children }) {
  const { user, logout, isAdmin } = useAuth();
  const [showBasic, setShowBasic] = useState(false);

  return (
    <>
      <MDBNavbar expand="lg" dark bgColor="primary">
        <MDBContainer fluid>
          <MDBNavbarBrand href="/">
            <img src={logo} height="30" alt="logo" loading="lazy" />
          </MDBNavbarBrand>

          <MDBNavbarToggler
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={() => setShowBasic(!showBasic)}
          >
            <MDBIcon icon="bars" fas />
          </MDBNavbarToggler>

          <MDBCollapse navbar show={showBasic}>
            <MDBNavbarNav className="mr-auto mb-2 mb-lg-0">
              <MDBNavbarItem>
                <MDBNavbarLink
                  aria-current="page"
                  href="/"
                  active={path === "/dashboard" || undefined}
                >
                  Home
                </MDBNavbarLink>
              </MDBNavbarItem>

              <MDBNavbarItem>
                <MDBDropdown>
                  <MDBDropdownToggle tag="a" className="nav-link">
                    Reports
                  </MDBDropdownToggle>
                  <MDBDropdownMenu>
                    {Object.values(reports).map((reportInfo) => (
                      <MDBDropdownItem
                        key={`report-${reportInfo.name}`}
                        className=""
                      >
                        <MDBDropdownLink
                          href={`/reports/${reportInfo.name}`}
                          active={""}
                        >
                          {reportInfo.label}
                        </MDBDropdownLink>
                      </MDBDropdownItem>
                    ))}
                  </MDBDropdownMenu>
                </MDBDropdown>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <MDBDropdown>
                  <MDBDropdownToggle tag="a" className="nav-link">
                    SCIP Reports
                  </MDBDropdownToggle>
                  <MDBDropdownMenu>
                    {Object.values(scipReports).map((reportInfo) => (
                      <MDBDropdownItem
                        key={`report-${reportInfo.name}`}
                        className=""
                      >
                        <MDBDropdownLink
                          href={`/reports/${reportInfo.name}`}
                          active={""}
                        >
                          {reportInfo.label}
                        </MDBDropdownLink>
                      </MDBDropdownItem>
                    ))}
                  </MDBDropdownMenu>
                </MDBDropdown>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <MDBDropdown>
                  <MDBDropdownToggle tag="a" className="nav-link">
                    Apportionments
                  </MDBDropdownToggle>
                  <MDBDropdownMenu>
                    {Object.values(apportionments).map((reportInfo) => (
                      <MDBDropdownItem
                        key={`apportionment-${reportInfo.name}`}
                        className=""
                      >
                        <MDBDropdownLink
                          href={`/apportionments/${reportInfo.name}`}
                          active={""}
                        >
                          {reportInfo.label}
                        </MDBDropdownLink>
                      </MDBDropdownItem>
                    ))}
                  </MDBDropdownMenu>
                </MDBDropdown>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <MDBDropdown>
                  <MDBDropdownToggle tag="a" className="nav-link">
                    Tools
                  </MDBDropdownToggle>
                  <MDBDropdownMenu>
                    {Object.values(tools).map((reportInfo) => (
                      <MDBDropdownItem
                        key={`tools-${reportInfo.name}`}
                        className=""
                      >
                        <MDBDropdownLink
                          href={`/tools/${reportInfo.name}`}
                          active={""}
                        >
                          {reportInfo.label}
                        </MDBDropdownLink>
                      </MDBDropdownItem>
                    ))}
                  </MDBDropdownMenu>
                </MDBDropdown>
              </MDBNavbarItem>

              {isAdmin() && (
                <>
                  <MDBNavbarItem>
                    <MDBNavbarLink
                      href="/dev"
                      active={path === "/dev" || undefined}
                    >
                      Development Mode
                    </MDBNavbarLink>
                  </MDBNavbarItem>

                  <MDBNavbarItem>
                    <MDBNavbarLink
                      href="/list"
                      active={path === "/list" || undefined}
                    >
                      See all reports
                    </MDBNavbarLink>
                  </MDBNavbarItem>
                  <MDBNavbarItem>
                    <MDBNavbarLink
                      href="/users"
                      active={path === "/users" || undefined}
                    >
                      Users Management
                    </MDBNavbarLink>
                  </MDBNavbarItem>
                </>
              )}
            </MDBNavbarNav>
            <MDBNavbarNav
              right
              className="mb-2 mb-lg-0 w-50 justify-content-end d-flex"
            >
              <MDBNavbarItem>
                <span className="navbar-text d-block text-white">
                  Hey, <strong>{user.username}</strong>!
                </span>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <MDBNavbarLink href="#" onClick={logout}>
                  <span className="text me-2">Logout</span>
                  <span className="icon">
                    <MDBIcon icon="sign-out-alt" fas />
                  </span>
                </MDBNavbarLink>
              </MDBNavbarItem>
            </MDBNavbarNav>
          </MDBCollapse>
        </MDBContainer>
      </MDBNavbar>
      <MDBContainer className="main-container mt-4">{children}</MDBContainer>
    </>
  );
}

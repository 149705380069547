import React, { useEffect, useState } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { useSelector } from "react-redux";
import { entitySelector } from "../../pages/reports/data.slice";

export default function DBField({
  id,
  name,
  value,
  updateValue,
  entity,
  keyField,
  valueField,
  disabled = false,
  multiple,
  ...others
}) {
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState(null);
  const data = useSelector(entitySelector(entity));

  const onChange = (items) => {
    const hasItems = items && items.length > 0;
    updateValue(hasItems ? multiple ? items.map((i) => i.id) : items[0].id : null);
    setSelectedOptions(hasItems ? items : null);
  };

  useEffect(() => {
    if (!data) {
      setOptions([]);
      return;
    }
    let uniqueLabels = [];
    setOptions(
      data.map((item) => {
        const label = typeof valueField === "function" ? valueField(item) : item[valueField];
        return { id: item[keyField], label };
      }).filter(({ label }) => {
        if (uniqueLabels.includes(label)) return false;
        uniqueLabels.push(label);
        return true;
      })
    );
  }, [data, entity, keyField, valueField]);

  useEffect(() => {
    if (!value) setSelectedOptions(null);
  }, [value]);

  return (
    <Typeahead
      id={id}
      labelKey="label"
      onChange={onChange}
      options={options}
      placeholder="Choose a value..."
      selected={disabled || !selectedOptions ? [] : selectedOptions}
      disabled={disabled}
      multiple={disabled ? false : multiple}
      {...others}
    />
  );
}

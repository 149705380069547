import { createAsyncThunk } from "@reduxjs/toolkit";
import AxiosErrorHandling from "../../utils/AxiosErrorHandling";
import HTTPStatus from "../../utils/HTTPStatus";
import UsersService from "./users.services";

export const FetchUsersThunk = createAsyncThunk(
  "users/all",
  async (_, thunkAPI) => {
    try {
      const response = await UsersService.fetchAllUsers();
      if (response.status < HTTPStatus.BAD_REQUEST) {
        return response.data;
      }

      return thunkAPI.rejectWithValue(AxiosErrorHandling(response));
    } catch (e) {
      return thunkAPI.rejectWithValue(AxiosErrorHandling(e));
    }
  }
);

export const FetchUserThunk = createAsyncThunk(
  "users/one",
  async (userId, thunkAPI) => {
    try {
      const response = await UsersService.fetchUser(userId);
      if (response.status < HTTPStatus.BAD_REQUEST) {
        return response.data;
      }

      return thunkAPI.rejectWithValue(AxiosErrorHandling(response));
    } catch (e) {
      return thunkAPI.rejectWithValue(AxiosErrorHandling(e));
    }
  }
);

export const UpdateUserThunk = createAsyncThunk(
  "users/update",
  async ({ id, ...userInfo }, thunkAPI) => {
    try {
      const response = await UsersService.updateUser(id, userInfo);
      if (response.status < HTTPStatus.BAD_REQUEST) {
        return response.data;
      }

      return thunkAPI.rejectWithValue(AxiosErrorHandling(response));
    } catch (e) {
      return thunkAPI.rejectWithValue(AxiosErrorHandling(e));
    }
  }
);

export const CreateUserThunk = createAsyncThunk(
  "users/create",
  async (userInfo, thunkAPI) => {
    try {
      const response = await UsersService.createUser(userInfo);
      if (response.status < HTTPStatus.BAD_REQUEST) {
        return response.data;
      }

      return thunkAPI.rejectWithValue(AxiosErrorHandling(response));
    } catch (e) {
      return thunkAPI.rejectWithValue(AxiosErrorHandling(e));
    }
  }
);

export const DeleteUserThunk = createAsyncThunk(
  "users/delete",
  async (userId, thunkAPI) => {
    try {
      const response = await UsersService.deleteUser(userId);
      if (response.status < HTTPStatus.BAD_REQUEST) {
        return response.data;
      }

      return thunkAPI.rejectWithValue(AxiosErrorHandling(response));
    } catch (e) {
      return thunkAPI.rejectWithValue(AxiosErrorHandling(e));
    }
  }
);

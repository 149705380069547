const scipDelinquencyDistrictReport = {
    label: "SCIP Delinquency Report - District",
    name: "scipDelinquencyDistrictReport",
    parameters: [
      {
        name: "taxYear",
        label: "Tax year",
        type: "year",
        defaultValue: "2023",
      },
      {
        name: "countyId",
        label: "County",
        type: "dbfield",
        db: "scip",
        table: "County",
        keyField: "CountyId",
        valueField: "Name",
        join: {
          Parcel: "CountyId",
        },
        sort: {
          Name: "asc",
        },
      },
      {
        name: "districtId",
        label: "District",
        type: "dbfield",
        db: "scip",
        table: "District",
        keyField: "DistrictId",
        valueField: "DistrictName",
        sort: {
          DistrictName: "asc",
        },
        dependsOn: "countyId",
      },
    ],
  };
  
  export default scipDelinquencyDistrictReport;

export const heroDelinquencyReport = {
  label: "Hero Delinquency Report",
  name: "heroDelinquencyReport",
  parameters: [
    {
      name: "taxYear",
      label: "Tax year",
      type: "year",
    },
  ],
};

export const taxPaymentDelinquencyReport = {
  label: "CA1st Tax Payment Delinquency Report",
  name: "taxPaymentDelinquencyReport",
  parameters: [
    {
      name: "taxYear",
      label: "Tax year",
      type: "year",
    },
  ],
};

export const pfDelinquencyReport = {
  label: "Pace Funding Tax Payment Delinquency Report",
  name: "pfDelinquencyReport",
  parameters: [
    {
      name: "taxYear",
      label: "Tax year",
      type: "year",
    },
  ],
};

export const newDelinquencyReport = {
  label: "Delinquency Report",
  name: "newDelinquencyReport",
  parameters: [
    {
      name: "taxYear",
      label: "Tax year",
      type: "year",
      defaultValue: "2023",
    },
    {
      name: "db",
      label: "Database",
      type: "select",
      defaultValue: "hero",
      options: [
        { key: "hero", label: "HERO" },
        { key: "data", label: "DATA" },
        { key: "cfd", label: "CFD" },
        { key: "scip", label: "SCIP" },
      ],
    },
    {
      name: "programs",
      label: "Program Associated IDs",
      type: "dbfield",
      table: "Program",
      keyField: "ProgramAssociatedId",
      valueField: "ProgramAssociatedId",
      where: {
        ProgramAssociatedId: { 
          null: false,
        },
      },
      sort: {
        ProgramAssociatedId: "asc",
      },
      dependsOn: "db",
      dependsOnHaving: ['hero', 'data'],
      multiple: true,
    },
    {
      name: "countyId",
      label: "County",
      type: "dbfield",
      table: "County",
      keyField: "CountyId",
      valueField: (row) => `${row.Name} (${row.State})`,
      sort: {
        Name: "asc",
      },
      dependsOn: "db",
      dependsOnHaving: ['cfd', 'scip'],
    },
  ],
};

import { createSlice } from "@reduxjs/toolkit";
import Status from "../../utils/Status";
import { FetchDataThunk } from "./data.thunk";

const initialState = {
  data: {},
  status: Status.INITIAL_LOADING,
  error: null,
};

export const dataSlice = createSlice({
  name: "Data",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(FetchDataThunk.fulfilled, (state, action) => {
        const { payload } = action;
        if (payload) {
          state.status = Status.IDLE;
          state.data = {
            ...state.data,
            [payload.entity]: payload.data,
          };
        }
      })
      .addCase(FetchDataThunk.pending, (state) => {
        state.status = Status.LOADING;
      })
      .addCase(FetchDataThunk.rejected, (state, { payload }) => {
        state.status = Status.FAILED;
        state.error = payload;
      });
  },
});

export default dataSlice.reducer;

// selectors
export const statusSelector = (state) => state.Data.status;
export const dataSelector = (state) => state.Data.data;
export const entitySelector = (entity) => (state) => state.Data.data[entity];
